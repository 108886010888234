import { ReactNode } from 'react';
import {
  TeamIntegrationFeature,
  TeamIntegrationProvider,
} from '../../../../typings/TeamIntegration.interface';
import { IconName } from '../components/common/base/Icon';
import { Div } from '../components/common/helpers/StyledUtils';
import Text from '../components/common/base/Text';

type Fields = {
  label: string;
  name: string;
  type: 'text' | 'select' | 'password' | 'number' | 'radio';
  placeholder?: string;
  required?: boolean;
  options?: { value: string; label: string }[];
  inline?: boolean;
}[];

export interface TeamIntegrationSchema {
  icon: IconName;
  label: string;
  features: TeamIntegrationFeature[];
  fields?: Fields;
  how_steps?: string[];
  onClick?: () => void;
  callback?: (data: any) => void;
  configure_step?: {
    label: string;
    description: string;
    button_text: string;
    button_href: string;
    callback?: (data: any) => void;
  };
  alert_banner?: ReactNode;
}

const team_integration_schemas: {
  [key in Exclude<TeamIntegrationProvider, 'email'>]: TeamIntegrationSchema;
} = {
  slack: {
    icon: 'slack_colored',
    label: 'Slack',
    features: ['notification'],
    onClick: () => {
      window.location.replace(
        `${process.env.SLACK_SHARABLE_URL}&redirect_uri=${process.env.SLACK_REDIRECT_URI}`,
      );
    },
    callback: (data: any) => {
      return {
        access_token: data.code,
      };
    },
    alert_banner: (
      <Div>
        To connect Hookdeck to a private Slack channel, first type{' '}
        <Text display="inline-block" monospace heading>
          /invite @Hookdeck
        </Text>{' '}
        in the channel. The channel will then populate in the dropdown below.
      </Div>
    ),
  },
  opsgenie: {
    icon: 'opsgenie_colored',
    label: 'Opsgenie',
    features: ['notification'],
    fields: [{ label: 'API key', name: 'api_key', type: 'password' }],
    how_steps: [
      'Head to your Team’s Dashboard,',
      'Click <b>Teams</b> in the top nav bar,',
      'Select a <b>Team</b> or <b>Add Team</b>,',
      'In the Team’s page, navigate to <b>Integrations</b> from the left sidebar,',
      'Click <b>Add Integration</b> and search for <b>API</b>,',
      'Enter your <b>API Key</b> in the next section,',
      'Enable <b>Read Access</b>, <b>Create and Update Access</b>, and <b>Enabled</b>,',
      'Select <b>Save Integration</b>.',
    ],
  },
  pagerduty: {
    icon: 'pagerduty',
    label: 'PagerDuty',
    features: ['notification'],
    onClick: () => {
      window.location.replace(
        `https://app.pagerduty.com/install/integration?app_id=${process.env.PAGERDUTY_APP_ID}&redirect_url=${process.env.PAGERDUTY_REDIRECT_URL_ROOT + '/configure'}&version=2`,
      );
    },
    // Pagerduty has 2 "oauth" steps, one for the "integration key" and one for the oauth code. Because it's not standard we're adding a manual step here called "configure"
    configure_step: {
      label: 'Authorize Hookdeck with Pagerduty',
      description:
        'Beyond providing your target service to link with Hookdeck. You also need to grant authorization to Hookdeck to use the Pagerduty API.',
      button_text: 'Authorize',
      button_href: `https://identity.pagerduty.com/oauth/authorize?client_id=${process.env.PAGERDUTY_CLIENT_ID}&scope=write&response_type=code&redirect_uri=${process.env.PAGERDUTY_REDIRECT_URL_ROOT + '/connect'}`,
      callback: (data: { config: string }) => {
        sessionStorage.setItem('integrations:pagerduty:integration_keys', data.config);
      },
    },
    callback: (data: any) => {
      const storage_keys = sessionStorage.getItem('integrations:pagerduty:integration_keys');

      if (!storage_keys) {
        throw new Error('No integration keys found');
      }

      const parsed_keys = JSON.parse(storage_keys);

      return {
        services: parsed_keys.integration_keys.filter((service) => service.type === 'service'),
        oauth_code: data.code,
      };
    },
  },
  datadog: {
    icon: 'datadog',
    label: 'Datadog',
    features: ['metrics'],
    fields: [{ label: 'API key', name: 'api_key', type: 'password' }],
    how_steps: [
      'Head to your Datadog Dashboard,',
      'Click <b>Organization Settings</b> from the left sidebar,',
      'Select <b>API Keys</b> in the settings list,',
      'In the API Keys page, click <b>New Key</b> to generate a new API key,',
      'Enter the <b>API Key</b> in the below API Key field,',
      'Click <b>Connect</b> to save the integration setup to Datadog, your metrics will start sending to Datadog within a few minutes.',
    ],
  },
};

export default team_integration_schemas;
