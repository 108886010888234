import {
  DelayRule,
  FilterRule,
  RetryRule,
  TransformRule,
} from '../../../../typings/Ruleset.interface';
import { Transformation } from '../../../../typings/Transformation.interface';
import { WebhookFilterProperty } from '../../../../typings/Webhook.interface';
import HookdeckAPI from '../client/Hookdeck';
import LINKS from '../configs/links';

export const RULE_ORDER: Record<keyof typeof validate, number> = {
  transform: 0,
  filter: 1,
  delay: 2,
  retry: 4,
};

export const INTERVAL_TIME_UNITS = {
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
};

export const RETRY_STATEGY_LABELS = {
  linear: 'Linearly',
  exponential: 'Exponentially',
};

export const getMsIntervalByUnit = (retry_interval: number): [number, string] => {
  for (const [unit, multiplier] of Object.entries(INTERVAL_TIME_UNITS).reverse()) {
    const unit_interval = retry_interval / multiplier;
    if (unit_interval % 1 === 0) {
      return [unit_interval, unit];
    }
  }
  throw new Error('Invalid retry interval: ' + retry_interval);
};

const complete_rule_pattern = /^(!?[2-5]\d{2}|[<>]=?[2-5]\d{2}|[2-5]\d{2}-[2-5]\d{2})$/;

const validateHttpCodes = (value: string): string | undefined => {
  if (!value) return undefined;

  const rules = value.split(',').map((rule) => rule.trim());
  const validPartialRulePattern = /^(!|[<>]=?)?([2-5]\d{0,2})?(-[2-5]\d{0,2})?$/;

  for (const rule of rules) {
    // Ignore incomplete rules, but validate format
    if (!validPartialRulePattern.test(rule)) {
      return `Invalid rule format: ${rule}. Please use the format: >300, >=300, <400, <=400, 404, !401, 500-599`;
    }

    // Only validate complete rules
    if (complete_rule_pattern.test(rule)) {
      if (rule.includes('-')) {
        const [min, max] = rule.split('-').map(Number);
        if (min >= max) {
          return `Invalid range: ${rule}. The first number should be less than the second.`;
        }
      }

      const numericValue = rule.replace(/[!<>=]/g, '');
      const code = parseInt(numericValue, 10);
      if (code < 200 || code > 599) {
        return `Invalid HTTP code: ${numericValue}. Please use codes between 200 and 599.`;
      }
    }
  }

  return undefined;
};

const validate = {
  filter: async ({ headers, body, path, query }) => {
    const errors: {
      body?: string;
      headers?: string;
      path?: string;
      query?: string;
    } = {};
    try {
      headers && JSON.parse(headers);
    } catch (e) {
      errors.headers = 'Invalid headers JSON';
    }
    try {
      body && JSON.parse(body);
    } catch (e) {
      errors.body = 'Invalid body JSON';
    }
    try {
      path && JSON.parse(path);
    } catch (e) {
      errors.path = 'Invalid path JSON';
    }
    try {
      query && JSON.parse(query);
    } catch (e) {
      errors.query = 'Invalid query JSON';
    }
    return errors;
  },
  transform: async () => {
    return {};
  },
  retry: ({ count, interval, strategy, response_status_codes }) => {
    const errors: {
      count?: string;
      interval?: string;
      strategy?: string;
      retries_error?: string;
      response_status_codes?: string;
    } = {};
    if (!count) {
      errors.count = 'Count required';
    }
    if (!interval) {
      errors.interval = 'Interval required';
    }
    if (!strategy) {
      errors.strategy = 'Strategy required';
    }
    if (count > 50) {
      errors.count = 'Retry count must be equal or less than 50';
    }
    if (response_status_codes) {
      const error = validateHttpCodes(response_status_codes);
      if (error) {
        errors.response_status_codes = error;
      }
    }

    if (count && interval && strategy) {
      const max_retry_distance = 1000 * 60 * 60 * 24 * 7;
      let distance;
      if (!strategy || strategy === 'linear') {
        distance = count * interval;
      } else {
        distance = Math.pow(2, count - 1) * interval;
      }
      if (distance > max_retry_distance) {
        errors.strategy =
          "The retry count, interval and strategy can't lead to retries further then 1 week in the future.";
      }
    }
    return errors;
  },
  delay: ({ delay }) => {
    const errors: {
      delay?: string;
    } = {};

    if (!delay) {
      errors.delay = 'Delay required';
    }

    if (delay < 1000) {
      errors.delay = "Delay can't be shorter than 1 second";
    } else if (delay > 1000 * 60 * 60 * 24) {
      errors.delay = "Delay can't be over 24 hours";
    }

    return errors;
  },
};

export const validateFormRules = async (rules: any, HookdeckAPI: HookdeckAPI) => {
  const errors = await Promise.all(rules.map((r) => validate[r.type](r, HookdeckAPI)));
  return errors.filter((r) => Object.keys(r).length > 0).length > 0 ? errors : null;
};

const preprocessWebhookFilterProperty = (property?: WebhookFilterProperty) => {
  if (property === null || typeof property === 'undefined') {
    return '';
  }
  return JSON.stringify(property, null, '\t');
};

const preprocess = {
  retry: (rule: RetryRule) => {
    return {
      ...rule,
      response_status_codes: rule.response_status_codes
        ? rule.response_status_codes.join(', ')
        : '',
    };
  },
  filter: (rule: FilterRule) => {
    return {
      ...rule,
      headers: preprocessWebhookFilterProperty(rule?.headers),
      body: preprocessWebhookFilterProperty(rule?.body),
      path: preprocessWebhookFilterProperty(rule?.path),
      query: preprocessWebhookFilterProperty(rule?.query),
    };
  },
};

export const preprocessFormRules = (rules: any) => {
  return rules.map((r) => (preprocess[r.type] ? preprocess[r.type](r) : r));
};

const postprocess = {
  retry: (input): RetryRule | undefined => {
    if (input.response_status_codes) {
      const rules: string[] = input.response_status_codes.split(',').map((rule) => rule.trim());
      input.response_status_codes = rules.filter((rule) => complete_rule_pattern.test(rule));
    } else {
      input.response_status_codes = undefined;
    }

    return input;
  },
  filter: (input): FilterRule | undefined => {
    delete input.event_id;
    delete input.test_input;

    const filter = {
      ...input,
      headers: input.headers ? JSON.parse(input.headers) : undefined,
      body: input.body ? JSON.parse(input.body) : undefined,
      path: input.path ? JSON.parse(input.path) : undefined,
      query: input.query ? JSON.parse(input.query) : undefined,
    };

    if (
      filter.headers === undefined &&
      filter.body === undefined &&
      filter.path === undefined &&
      filter.query === undefined
    ) {
      return undefined;
    }
    return filter;
  },
  transform: (input): TransformRule | undefined => {
    if (!input.transformation.name) {
      return undefined;
    }
    if (input.transformation.env && Array.isArray(input.transformation.env)) {
      input.transformation.env = input.transformation.env.reduce(
        (object, [key, value]) => ({ ...object, [key]: value }),
        {},
      );
    }
    delete input.input;
    return input;
  },
};

export const postprocessFormRules = (rules: any) => {
  return rules.map((r) => (postprocess[r.type] ? postprocess[r.type](r) : r)).filter((r) => !!r);
};

export const RULE_TYPES = {
  retry: {
    label: 'Retry',
    icon: 'retry',
    description: 'Configure a automatic retry strategy for any event delivery failures.',
    docs: LINKS.product_docs.retries,
    getLabel: (rule: RetryRule): string => {
      if (!rule.strategy || rule.count === 0) {
        return `Retries disabled`;
      }
      const [interval, unit] = getMsIntervalByUnit(rule.interval);
      return `Retry ${RETRY_STATEGY_LABELS[rule.strategy].toLowerCase()} every ${interval} ${
        interval === 1 ? unit.slice(0, -1) : unit
      } up to ${rule.count}
      times`;
    },
  },
  filter: {
    label: 'Filter',
    icon: 'filter',
    description: 'Filter the event based on the request body, headers, query or path.',
    docs: LINKS.product_docs.filters,
    getLabel: (rule: FilterRule): string => {
      const active_keys = Object.keys(rule).filter((rule) => rule !== 'type');
      return `Filtering on ${active_keys.length === 0 ? 'nothing' : active_keys.join(', ')} `;
    },
  },
  transform: {
    label: 'Transform',
    icon: 'transformation',
    description: 'Alter the request body, headers, query or path with using a Javascript function.',
    docs: LINKS.product_docs.transformations,
    getLabel: (rule, transformation: Transformation): string => {
      return transformation?.name;
    },
  },
  delay: {
    label: 'Delay',
    icon: 'schedule',
    description: 'Set delay between the time an event is received and is delivered.',
    docs: LINKS.product_docs.connection_delay,
    getLabel: (rule: DelayRule): string => {
      const [interval, unit] = getMsIntervalByUnit(rule.delay);
      return `Delaying for ${interval} ${interval === 1 ? unit.slice(0, -1) : unit}`;
    },
  },
} as const;
