import { Form, Formik } from 'formik';
import { useContext, useEffect, useMemo, useState } from 'react';

import { Source } from '../../../../../../../../typings/Source.interface';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import Button from '../../../../common/base/Button';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import resource_details_form_props from '../../Connections/Forms/resource_details';
import prev_source_configuration_form_props, {
  PrevSourceConfigurationFormValues,
} from '../../Connections/Forms/prev_source_configuration';
import source_configuration_form_props, {
  SourceConfigurationFormValues,
} from '../../Connections/Forms/source_configuration';
import ApiModal from '../ApiModal';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { DashboardContext } from '../../DashboardContext';
import { mergeIntegrationSchemas } from '../../../../../configs/integration-schemas';

const AddSource: React.FC<{
  context: { source: Source | null };
  mutateContext: (any) => void;
  nextStep: () => void;
}> = ({ context: { source }, mutateContext, nextStep }) => {
  const use_source_types = useFeatureFlag('types');
  const { HookdeckAPI } = useContext(GlobalContext);
  const { source_types: _source_types } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const [render_key, setRenderKey] = useState(1);
  const [show_api_modal, showAPIModal] = useState(false);

  const source_types = useMemo(
    () =>
      use_source_types || !_source_types ? _source_types : mergeIntegrationSchemas(_source_types),
    [use_source_types, _source_types],
  );

  const configuration_form_props = use_source_types
    ? source_configuration_form_props
    : prev_source_configuration_form_props;

  useEffect(() => {
    if (source) {
      setRenderKey((prev) => prev + 1);
    }
  }, [source?.allowed_http_methods, source?.verification]);

  return (
    <Formik
      key={render_key}
      initialValues={{
        ...resource_details_form_props.getInitialValues(source || undefined),
        ...configuration_form_props.getInitialValues(source || undefined),
      }}
      validateOnMount
      validate={async (values) =>
        cleanseFormErrorObject({
          ...(await resource_details_form_props.validate(
            values,
            (name) =>
              source && name === source.name
                ? Promise.resolve(false)
                : HookdeckAPI.sources.nameIsUsed(name),
            true,
          )),
          ...(await configuration_form_props.validate(
            values as SourceConfigurationFormValues & PrevSourceConfigurationFormValues,
            source_types!,
          )),
        })
      }
      onSubmit={(v, { resetForm }) => {
        const values = {
          ...resource_details_form_props.postprocessValues(v),
          ...configuration_form_props.postprocessValues(
            v as SourceConfigurationFormValues & PrevSourceConfigurationFormValues,
            source_types!,
          ),
        };
        const promise = source
          ? HookdeckAPI.sources.update(source.id, values)
          : HookdeckAPI.sources.create(values);
        return promise
          .then((new_source) => {
            const promise = new_source.verification
              ? HookdeckAPI.sources.get(new_source.id, {
                  include: 'verification.configs',
                })
              : Promise.resolve(new_source);

            promise.then((new_source) => {
              mutateContext({ source: new_source });
              resetForm({
                values: {
                  ...v,
                  ...resource_details_form_props.getInitialValues(new_source || undefined),
                  ...configuration_form_props.getInitialValues(new_source || undefined),
                  show_advanced: v.show_advanced,
                },
              });
              if (source) {
                addToast('success', `Source ${new_source.name} configuration updated`);
              }
              nextStep();
            });
          })
          .catch((e) => {
            addToast(
              'error',
              `An error occurred while saving the source${
                e.response?.data[0] ? `: ${e.response.data[0]}` : ''
              }`,
            );
            return;
          });
      }}>
      {({ isValid, isSubmitting, handleSubmit, dirty, values, errors }) => (
        <Form onSubmit={handleSubmit}>
          {!use_source_types && (
            <resource_details_form_props.Fields
              prefix=""
              placeholder="shopify-prod"
              name_prefix="Source"
              name_required
            />
          )}
          <Div m={{ t: use_source_types ? 0 : 6 }}>
            <configuration_form_props.Fields prefix="" />
          </Div>
          <Div flex={{ gap: 3 }} m={{ t: 8 }}>
            <Button
              submit
              disabled={!isValid || isSubmitting || (!!source && !dirty)}
              primary={!source}
              outline={!!source}
              icon={isSubmitting ? 'loading' : source ? 'save' : 'add_circle'}>
              {source ? 'Update Source' : 'Create Source'}
            </Button>
            <Button minimal icon="code" onClick={() => showAPIModal(true)} disabled={!isValid}>
              Use API
            </Button>
            {show_api_modal && (
              <ApiModal
                action="create-source"
                onClose={() => showAPIModal(false)}
                values={{
                  ...resource_details_form_props.postprocessValues(values),
                  ...configuration_form_props.postprocessValues(
                    values as SourceConfigurationFormValues & PrevSourceConfigurationFormValues,
                    source_types!,
                  ),
                }}
              />
            )}
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default AddSource;
