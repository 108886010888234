import { useField } from 'formik';
import { useContext, useState } from 'react';
import useSWR from 'swr';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { Div } from '../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import destination_configuration_form_props from '../Forms/prev_destination_configuration';
import new_destination_configuration_form_props from '../Forms/destination_configuration';
import resource_details_form_props from '../Forms/resource_details';
import RadioCardsInput from '../../../../common/Form/Fields/RadioCardsInput';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';

const DestinationSection = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const [{ value: use }] = useField('destination_use');
  const use_source_types = useFeatureFlag('types');
  const [search_term, setSearchTerm] = useState<string>('');

  const filters: Record<string, any> = {
    limit: 100,
    name: search_term ? { contains: search_term } : undefined,
  };
  const { data: destinations, isValidating } = useSWR(
    APIMethodKeys.destinations.list(filters),
    () => HookdeckAPI.destinations.list(filters),
  );

  return (
    <>
      <RadioCardsInput
        name={'destination_use'}
        m={{ b: 6 }}
        options={[
          {
            value: 'new',
            label: 'Create new destination',
            description: 'Configure a new destination in Hookdeck.',
          },
          {
            value: 'select',
            label: 'Use existing destination',
            description: 'Re-use a destination you’ve already created.',
            disabled: !destinations?.models.length,
          },
        ]}
      />
      {use === 'select' && (
        <SelectInput
          name="destination_id"
          label="Destination"
          required
          search
          placeholder="Select..."
          search_placeholder="Find a destination..."
          separator={false}
          borderless
          sublte_error
          options={
            destinations?.models.map((destination) => ({
              value: destination.id,
              label: destination.name,
              icon: destination.cli_path ? 'terminal' : 'http',
            })) || []
          }
          width={100}
          block
          search_term={search_term}
          setSearchTerm={setSearchTerm}
          search_is_loading={isValidating}
        />
      )}
      {use === 'new' &&
        (use_source_types ? (
          <Div m={{ t: 6 }}>
            <new_destination_configuration_form_props.Fields prefix="destination" />
          </Div>
        ) : (
          <>
            <resource_details_form_props.Fields
              prefix="destination"
              placeholder="my-api"
              name_prefix="Destination"
            />
            <Div m={{ t: 6 }}>
              <destination_configuration_form_props.Fields prefix="destination" />
            </Div>
          </>
        ))}
    </>
  );
};

export default DestinationSection;
