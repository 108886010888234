import { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import {
  EventView as IEventView,
  RequestView as IRequestView,
} from '../../../../../../typings/View.interface';
import { formatPath } from '../../../utils/path';
import ErrorBoundary from '../../common/helpers/ErrorBoundary';
import ViewRoute from '../../common/ViewRoute';
import { TeamPermission } from '../../contexts/TeamPermissionContext';
import useScrollToTop from '../../hooks/useScrollToTop';
import BookmarksView from './Bookmarks';
import CliAuthView from './CliAuth';
import ConnectionView from './Connections/Connection/ConnectionView';
import ConnectionsView from './Connections';
import CreateConnectionView from './Connections/Create/CreateView';
import {
  DashboardContext,
  DashboardContextProvider,
  WithTeamOnly,
  WithUserOnly,
} from './DashboardContext';
import EventView from './Event';
import EventsView from './Events';
import ForceUpgradeModal from './ForceUpgradeModal';
import IssueView from './Issue';
import IssuesView from './Issues';
import IssueTriggersListView from './IssueTriggers';
import IssueTriggerView from './IssueTriggers/IssueTrigger/IssueTriggerView';
import MetricsView from './Metrics';
import { metric_categories } from './Metrics/metric-categories';
import MetricCategoryView from './Metrics/MetricCategory';
import DashboardNav from './Nav';
import OnboardingView from './Onboarding';
import { redirects } from './redirects';
import RequestView from './Request';
import RequestsView from './Requests';
import Settings from './Settings';
import TeamSelect from './TeamSelect';
import TransformationView from './Transformation';
import { ViewsContextProvider } from './ViewsContext';
import TeamMemberOverLimitModal from './TeamMemberOverLimitModal';
import { DialogProvider } from '../../common/Dialog';
import GuidedConnection from './GuidedConnection/GuidedConnection';
import GetStartedView from './GetStarted';
import posthog from 'posthog-js';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import Icon from '../../common/base/Icon';
import Button from '../../common/base/Button';
import { Div } from '../../common/helpers/StyledUtils';
import Text from '../../common/base/Text';
import getThemes from '@hookdeck/theme/src/themes';
import Link from '../../common/base/Link';
import { useDegradedBannerDismissedAt, useIsDegradedBanner } from '../../hooks/useIsDegradedBanner';
import PrevSourceView from './Connections/Source/PrevSourceView';
import SourceView from './Connections/Source/SourceView';
import PrevDestinationView from './Connections/Destination/PrevDestinationView';
import DestinationView from './Connections/Destination/DestinationView';

const DashboardLayout = styled.div<{ nav_collapsed: boolean; degraded_banner_active: boolean }>`
  background-color: ${({ theme }) => theme.colors.surface.base.background};
  min-height: ${({ degraded_banner_active }) =>
    degraded_banner_active ? `calc(100vh - 32px)` : '100vh'};
  display: grid;
  grid-template-columns: ${({ theme, nav_collapsed }) =>
    nav_collapsed ? '1fr' : `${theme.pxToRem(224)} 1fr`};
`;

const StyledDashboardContent = styled.div<{ degraded_banner_active: boolean }>`
  background-color: ${({ theme }) => theme.colors.surface.base.background};
  min-height: ${({ degraded_banner_active }) =>
    degraded_banner_active ? `calc(100vh - 32px)` : '100vh'};
  flex-grow: 1;
  min-width: 0;
  position: sticky;
  top: ${({ degraded_banner_active }) => (degraded_banner_active ? `32px` : '0px')};
`;

const StyledDegradedServiceBanner = styled.div(({ theme: currentTheme }) => {
  const themes = getThemes();
  const theme = currentTheme.mode === 'light' ? themes.dark : themes.light;
  return css`
    background-color: ${theme.colors.surface.base.background};
    color: ${theme.colors.on.neutral.primary_neutral};
    height: 32px;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${({ theme }) => theme.spacing(4)};
    z-index: ${theme.zindex.sticky};

    a,
    button {
      color: ${theme.colors.on.neutral.primary_neutral};
      text-wrap: nowrap;

      &:hover,
      & > * {
        background-color: inherit;
        cursor: pointer;
      }
    }

    & > div:first-child,
    & > div:last-child {
      width: 150px;
    }

    @media (max-width: 835px) {
      .outage-description {
        display: none;
      }
    }
  `;
});

function DegradedServiceBanner() {
  const [, setDismissedAt] = useDegradedBannerDismissedAt();
  const is_degraded_banner = useIsDegradedBanner();

  if (!is_degraded_banner) return null;

  const handleBannerClose = () => {
    setDismissedAt(new Date().toISOString());
  };

  return (
    <StyledDegradedServiceBanner>
      <Div flex={{ gap: 1, align: 'center' }}>
        <Icon icon="info" />
        <Text heading text_wrap={false}>
          Service degradation
        </Text>
      </Div>
      <Div flex={{ gap: 1, align: 'center', justify: 'center' }}>
        <span className="outage-description">Hookdeck services are currently degraded.</span>
        <Link href="https://status.hookdeck.com/" target="_blank" icon="link">
          Follow updates on our status page
        </Link>
      </Div>
      <Div flex={{ gap: 1, align: 'center', justify: 'flex-end' }}>
        <Button minimal icon="close" p={0} onClick={handleBannerClose} />
      </Div>
    </StyledDegradedServiceBanner>
  );
}

const handleRouteChange = () => {
  posthog?.capture('$pageview');
};

const DashboardScene: React.FC = () => {
  const location = useLocation();
  const { view, nav_collapsed, subscription } = useContext(DashboardContext);
  const [currentRoute, setCurrentRoute] = useState<string | null>(null);
  const use_source_types = useFeatureFlag('types');
  const is_degraded_banner = useIsDegradedBanner();

  useEffect(() => {
    if (currentRoute === location.pathname) return;

    handleRouteChange();
    setCurrentRoute(location.pathname);
  }, [location.pathname, currentRoute]);

  useScrollToTop();

  return (
    <>
      <Switch>
        {/* Routes without team */}
        <ViewRoute
          usePathAsTitle
          capitalizeSubtitle
          path="/onboarding"
          component={OnboardingView}
        />
        <ViewRoute
          title={'Select Project'}
          usePathAsTitle
          path={'/projects'}
          component={TeamSelect}
        />
        {/* Routes with team */}
        <Route
          render={() => (
            <WithTeamOnly>
              <TeamPermission role="viewer">
                <Switch>
                  <ViewRoute
                    title={'Create Connection'}
                    usePathAsTitle
                    path={'/create-first-connection'}
                    component={GuidedConnection}
                  />
                  <Route path="/cli-auth/:key" component={CliAuthView} />
                  <Route>
                    <DegradedServiceBanner />
                    <DashboardLayout
                      nav_collapsed={nav_collapsed}
                      degraded_banner_active={is_degraded_banner}>
                      {!nav_collapsed && <DashboardNav />}
                      <StyledDashboardContent id="main" degraded_banner_active={is_degraded_banner}>
                        <ErrorBoundary>
                          <ForceUpgradeModal />
                          <TeamMemberOverLimitModal />
                          <Switch>
                            <Redirect
                              path="/webhooks"
                              to={{
                                ...location,
                                pathname: location.pathname.replace('/webhooks', '/connections'),
                              }}
                            />
                            <Route path="/settings" component={Settings} />
                            <ViewRoute
                              usePathAsTitle
                              path="/connections/new"
                              component={CreateConnectionView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path="/sources/:id"
                              component={use_source_types ? SourceView : PrevSourceView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path="/destinations/:id"
                              component={use_source_types ? DestinationView : PrevDestinationView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path="/connections/:id"
                              component={ConnectionView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path="/connections"
                              component={ConnectionsView}
                            />
                            <Route
                              path={['/cli/events', '/events']}
                              render={() => (
                                <ViewsContextProvider
                                  type="events"
                                  redirect_root={`${view === 'cli' ? '/cli' : ''}/events`}>
                                  {({ views }) =>
                                    views && (
                                      <Switch>
                                        {views.models.map((view) => (
                                          <ViewRoute
                                            title={'Events'}
                                            subtitle={view.name}
                                            key={view.id}
                                            path={[
                                              `/events/${formatPath(view.name)}`,
                                              `/cli/events/${formatPath(view.name)}`,
                                            ]}>
                                            <EventsView current_view={view as IEventView} />
                                          </ViewRoute>
                                        ))}
                                        <ViewRoute
                                          usePathAsTitle
                                          path={['/events/:id', '/cli/events/:id']}
                                          component={EventView}
                                        />
                                        <ViewRoute usePathAsTitle path={['/events', '/cli/events']}>
                                          <EventsView />
                                        </ViewRoute>
                                      </Switch>
                                    )
                                  }
                                </ViewsContextProvider>
                              )}
                            />
                            <Route
                              path={['/requests', '/cli/requests']}
                              render={() => (
                                <ViewsContextProvider
                                  type="requests"
                                  redirect_root={`${view === 'cli' ? '/cli' : ''}/requests`}>
                                  {({ views }) =>
                                    views && (
                                      <Switch>
                                        {views.models.map((view) => (
                                          <ViewRoute
                                            title={'Requests'}
                                            subtitle={view.name}
                                            key={view.id}
                                            path={[
                                              `/requests/${formatPath(view.name)}`,
                                              `/cli/requests/${formatPath(view.name)}`,
                                            ]}>
                                            <RequestsView current_view={view as IRequestView} />
                                          </ViewRoute>
                                        ))}
                                        <ViewRoute
                                          usePathAsTitle
                                          path={['/requests/:id', '/cli/requests/:id']}
                                          component={RequestView}
                                        />
                                        <ViewRoute
                                          usePathAsTitle
                                          path={['/requests', '/cli/requests']}>
                                          <RequestsView />
                                        </ViewRoute>
                                      </Switch>
                                    )
                                  }
                                </ViewsContextProvider>
                              )}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path={'/get-started'}
                              component={GetStartedView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path={['/bookmarks', '/cli/bookmarks']}
                              component={BookmarksView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path="/transformations/:id*"
                              component={TransformationView}
                            />
                            <ViewRoute usePathAsTitle path="/issues/:id" component={IssueView} />
                            <ViewRoute usePathAsTitle exact path="/issues" component={IssuesView} />
                            <ViewRoute
                              usePathAsTitle
                              exact
                              path={['/issue-triggers', '/issue-triggers/create/:type']}
                              component={IssueTriggersListView}
                            />
                            <ViewRoute
                              usePathAsTitle
                              path="/issue-triggers/:id"
                              component={IssueTriggerView}
                            />
                            {Object.keys(metric_categories).map((key) =>
                              subscription?.features?.includes('metrics') ? (
                                <ViewRoute
                                  key={key}
                                  usePathAsTitle
                                  capitalizeSubtitle
                                  path={`/metrics/${key}`}
                                  component={MetricCategoryView}
                                />
                              ) : (
                                <Redirect key={key} path={`/metrics/${key}`} to="/metrics" />
                              ),
                            )}
                            <ViewRoute usePathAsTitle path={`/metrics`} component={MetricsView} />
                            <Redirect to="/events" />
                          </Switch>
                        </ErrorBoundary>
                      </StyledDashboardContent>
                    </DashboardLayout>
                  </Route>
                </Switch>
              </TeamPermission>
            </WithTeamOnly>
          )}
        />
      </Switch>
    </>
  );
};

export default () => {
  const location = useLocation();

  return (
    <WithUserOnly>
      <Switch>
        {redirects.map((redirect) => (
          <Redirect
            key={redirect.from}
            from={redirect.from}
            to={{
              ...location,
              pathname: redirect.to,
            }}
            exact={redirect.exact}
          />
        ))}
      </Switch>
      <DashboardContextProvider>
        <DialogProvider>
          <DashboardScene />
        </DialogProvider>
      </DashboardContextProvider>
    </WithUserOnly>
  );
};
