import styled, { css } from 'styled-components';
import { useField, useFormikContext } from 'formik';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import Text from '../../base/Text';
import { ClickableArea } from '../../base/Button';
import { StyledCard, StyledCardSection } from '../../base/Card';
import Icon from '../../base/Icon';

interface SelectOption {
  value: string;
  label: string;
}

interface Props extends StyledUtilsProps {
  label?: string;
  name: string;
  options: SelectOption[];
  help?: string;
  type?: string;
  placeholder?: string;
  minimal?: boolean;
}

const StyledField = styled(Div)<{ minimal?: boolean; large?: boolean }>(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    ${StyledCardSection} {
      width: 100%;
      justify-content: flex-start;

      &.active {
        color: ${theme.colors.on.hue_container.primary};
        position: relative;

        > button {
          background-color: ${theme.colors.surface.container.primary};
          outline: 1px solid ${theme.colors.outline.primary};
        }
      }

      button {
        border-radius: 0;
      }

      &:first-child {
        button {
          border-top-left-radius: calc(${theme.radius.normal} - 1px);
          border-top-right-radius: calc(${theme.radius.normal} - 1px);
        }
      }

      &:last-child {
        button {
          border-bottom-left-radius: calc(${theme.radius.normal} - 1px);
          border-bottom-right-radius: calc(${theme.radius.normal} - 1px);
        }
      }
    }
  `,
);

const SelectRowsInput: React.FC<Props> = (props) => {
  const { label, name, help, minimal, options } = props;
  const [field, { error, touched }, { setValue, setTouched }] = useField(name);
  const { submitCount } = useFormikContext();
  return (
    <>
      <StyledField minimal={minimal} large>
        {label && <label htmlFor={name}>{label}</label>}
        <StyledCard raised>
          {options.map((option) => (
            <StyledCardSection
              key={option.value}
              className={field.value === option.value ? 'active' : ''}>
              <ClickableArea
                p={{ x: 4, y: 2.5 }}
                primary={field.value === option.value}
                onClick={() => {
                  setValue(option.value);
                  setTouched(true);
                }}>
                <Text>{option.label}</Text>
              </ClickableArea>
            </StyledCardSection>
          ))}
        </StyledCard>
        {help && (
          <Text m={{ top: 1 }} as="span" muted>
            <Icon icon="info" left={1} />
            {help}
          </Text>
        )}
      </StyledField>
      {(touched || submitCount > 0) && error && (
        <Text m={{ top: 1, bottom: 0 }} as="p" danger>
          {error}
        </Text>
      )}
    </>
  );
};

export default SelectRowsInput;
