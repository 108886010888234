import { useContext } from 'react';
import { Destination } from '../../../../../../../typings/Destination.interface';
import LINKS from '../../../../configs/links';
import { Truncatable } from '../../../common/Truncatable';
import { ClickableArea, ClickableAreaProps } from '../../../common/base/Button';
import { StyledCard } from '../../../common/base/Card';
import Icon, { IconName } from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import { Div } from '../../../common/helpers/StyledUtils';
import { DashboardContext } from '../DashboardContext';
import {
  DestinationType,
  DestinationTypeSchema,
} from '../../../../../../../typings/Integration.interface';

interface Props extends Pick<ClickableAreaProps, 'to' | 'onClick'> {
  destination: Destination;
}

export const useDestinationType = (
  destination: Destination | undefined,
): { key: DestinationType | undefined; type: DestinationTypeSchema | undefined } => {
  const { destination_types } = useContext(DashboardContext);

  if (!destination) {
    return {
      key: undefined,
      type: undefined,
    };
  }
  if (destination.type) {
    return {
      key: destination.type,
      type: destination_types?.[destination.type],
    };
  }
  if (!destination.url) {
    return {
      key: 'CLI',
      type: destination_types?.CLI,
    };
  }
  if (destination.url === LINKS.mock_api) {
    return {
      key: 'MOCK_API',
      type: destination_types?.MOCK_API,
    };
  }
  return {
    key: 'HTTP',
    type: destination_types?.HTTP,
  };
};

const DestinationCard: React.FC<Props> = ({ destination, onClick, to }) => {
  const { type: destination_type } = useDestinationType(destination);
  return (
    <StyledCard overflow_hidden>
      <ClickableArea
        p={{ x: 2, y: 1.5 }}
        flex={{ justify: 'space-between', align: 'center' }}
        onClick={onClick}
        to={to}>
        <Div flex={{ align: 'center', justify: 'space-between' }} w={100}>
          <Div flex={{ align: 'center' }} w={100}>
            {destination_type?.icon && (
              <Icon icon={destination_type.icon as IconName} muted left pointer />
            )}
            <Text w={100} ellipsis monospace muted={!!destination.disabled_at}>
              <Truncatable text={destination.name} />
            </Text>
          </Div>
          {destination.disabled_at && (
            <Tooltip tooltip={`Disabled`} placement="bottom-end">
              <Icon muted icon="disable" right />
            </Tooltip>
          )}
          {destination.rate_limit && !destination.disabled_at && (
            <Tooltip
              placement="bottom-end"
              tooltip={
                destination.rate_limit_period === 'concurrent'
                  ? `Max delivery rate of ${destination.rate_limit} concurrent`
                  : `Max delivery rate of ${destination.rate_limit} per ${destination.rate_limit_period}`
              }>
              <Icon icon="rate_limit" right muted />
            </Tooltip>
          )}
        </Div>
      </ClickableArea>
    </StyledCard>
  );
};

export default DestinationCard;
