import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { StyledUtilsProps, getStyledUtils } from '../helpers/StyledUtils';
import Icon, { IconName } from './Icon';
import Text from './Text';
import { usePermissionAwareDisabled } from '../../contexts/TeamPermissionContext';
import { withStaticProperties } from '../../../utils/withStaticProperties';
import { TeamRole } from '../../../../../../typings/TeamMember.interface';
import StyledEllipsisOverflow from '../helpers/EllipsisOverflow';

interface Props extends StyledUtilsProps {
  label: string;
  label_icon?: IconName;
  icon?: 'add' | 'close';
  small?: boolean;
  monospace?: boolean;
  neutral?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  to?: string | Partial<Location> | ((location: Location) => Partial<Location> | string);
}

const StyledLabelButton = styled.button<Omit<Props, 'label' | 'label_icon' | 'icon' | 'onClick'>>(
  ({ theme, neutral, small, monospace }) => css`
    ${getStyledUtils}
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-size: ${theme.pxToRem(small ? 12 : 13)};
    line-height: ${theme.pxToRem(20)};
    background-color: ${neutral
      ? theme.colors.surface.base.surface
      : theme.colors.surface.container.primary};
    border-radius: ${theme.radius.small};
    border: none;
    padding: 0;
    cursor: pointer;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;

    ${monospace &&
    css`
      font-family:
        JetBrains Mono,
        monospace;
    `}

    a {
      text-decoration: none;
      max-width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: ${neutral
        ? `1px solid ${theme.colors.outline.neutral}`
        : `1px solid ${theme.colors.outline.primary}`};
      border-radius: ${theme.radius.small};
      pointer-events: none;
    }

    span:not(:first-child) {
      border-left: ${neutral
        ? `1px solid ${theme.colors.outline.neutral}`
        : `1px solid ${theme.colors.outline.primary}`};
    }

    div {
      white-space: nowrap;
      color: ${neutral
        ? theme.colors.on.neutral.primary_neutral
        : theme.colors.on.hue_container.primary};
    }

    > span:last-child {
      color: ${neutral
        ? theme.colors.on.neutral.secondary_neutral
        : theme.colors.on.hue_container.primary_variant};
    }

    &:hover {
      background-color: ${neutral
        ? theme.colors.surface.base.hover.neutral
        : theme.colors.surface.container.hover.primary};
      &::before {
        border-color: ${neutral
          ? theme.colors.outline.hover.neutral
          : theme.colors.outline.hover.container_primary};
      }
      span:not(:first-child) {
        border-color: ${neutral
          ? theme.colors.outline.hover.neutral
          : theme.colors.outline.hover.container_primary};
      }
    }

    &:active {
      background-color: ${neutral
        ? theme.colors.surface.base.active.neutral
        : theme.colors.surface.container.active.primary};
      &::before {
        border-color: ${neutral
          ? theme.colors.outline.active.neutral
          : theme.colors.outline.active.container_primary};
      }
      div:not(:first-child) {
        border-color: ${neutral
          ? theme.colors.outline.active.neutral
          : theme.colors.outline.active.container_primary};
      }
    }

    a:focus-visible,
    &:focus-visible {
      outline: none;
      &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
        border: 2px solid ${theme.colors.outline.focus.primary};
        border-radius: ${theme.radius.large};
      }
    }
  `,
);

const LabelButtonComponent: React.FC<Props> = ({
  neutral,
  onClick,
  label_icon,
  label,
  icon,
  small,
  to,
  ...props
}) => {
  const content = (
    <>
      <Text size={small ? 's' : 'm'} subtitle p={{ x: 1 }} flex={{ align: 'center' }}>
        {label_icon && <Icon icon={label_icon} left={1} pointer />}
        <StyledEllipsisOverflow>{label}</StyledEllipsisOverflow>
      </Text>
      {icon && <Icon icon={icon} p={0.5} pointer />}
    </>
  );
  if (to) {
    return (
      <StyledLabelButton neutral={neutral} {...props} as={'div' as any}>
        <Link to={to}>{content}</Link>
      </StyledLabelButton>
    );
  }
  return (
    <StyledLabelButton onClick={onClick} neutral={neutral} {...props}>
      {content}
    </StyledLabelButton>
  );
};

const LabelButtonPermission: React.FC<
  React.ComponentPropsWithRef<typeof LabelButtonComponent> & { role?: TeamRole }
> = ({ role, ...clickableAreaProps }) => {
  const disabled = usePermissionAwareDisabled(clickableAreaProps.disabled, role);

  return <LabelButtonComponent {...clickableAreaProps} disabled={disabled} />;
};

export default withStaticProperties(LabelButtonComponent, { Permission: LabelButtonPermission });
