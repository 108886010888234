import { isBefore, subDays } from 'date-fns';
import { useContext, useMemo } from 'react';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import { RequestListFiltersProps } from '../../../../typings/RequestList.interface';
import { cause_filter_breakdown, rejection_cause_labels } from '../../../../utils/rejection-causes';
import Alert from '../../../common/base/Alert';
import Button from '../../../common/base/Button';
import Icon from '../../../common/base/Icon';
import Link from '../../../common/base/Link';
import Text from '../../../common/base/Text';
import DisplayDate from '../../../common/DisplayDate';
import { Div } from '../../../common/helpers/StyledUtils';
import Table from '../../../common/Table';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { RequestListResult } from '../../../hooks/useRequestList';
import { DashboardContext } from '../DashboardContext';
import RequestListEmptyView from './RequestListEmptyView';
import Badge from '../../../common/base/Badge';
import LabelButton from '../../../common/base/LabelButton';
import { getCurrentTimezoneAbreviation } from '../../../../utils/date';
import { Request } from '../../../../../../../typings/Request.interface';
import { Event } from '../../../../../../../typings/Event.interface';
import { IgnoredEvent } from '../../../../../../../typings/IgnoredEvent.interface';

interface RequestListProps extends RequestListResult {
  active_filters?: object;
  selected_request_id?: string;
  filters: RequestListFiltersProps;
  order_by: 'ingested_at';
  selected_entry_id: string;
  total_count?: number;
  entries_list?: {
    entries: (Event | IgnoredEvent)[];
    fetched: boolean;
    has_next: boolean;
    validating: boolean;
  };
  onRequestSelected(id: string | null): void;
  onEntrySelected(id: string): void;
  onPaginationChanged(filters: object): void;
  retry: (
    request_id: string,
    webhook_ids?: string[],
  ) => Promise<{ request: Request; events: Event[] }>;
}

const RequestsList: React.FC<RequestListProps> = ({
  selected_request_id,
  onRequestSelected,
  selected_entry_id,
  onEntrySelected,
  order_by,
  onPaginationChanged,
  filters,
  requests,
  outdated_ids,
  count,
  fetched,
  has_next_results,
  total_count,
  prev,
  next,
  retry,
  refresh,
  entries_list,
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, team, view, organization } = useContext(DashboardContext);

  const unique_source_ids = useMemo(
    () =>
      requests ? Array.from(new Set(requests.map((request) => request.source_id))).sort() : [],
    [requests],
  );

  const { data: sources } = useSWR(
    APIMethodKeys.sources.list(
      unique_source_ids.length > 0 ? { id: unique_source_ids } : { limit: 1 },
    ),
    () =>
      HookdeckAPI.sources.list(
        unique_source_ids.length > 0 ? { id: unique_source_ids } : { limit: 1 },
      ),
  );

  const sources_by_id =
    sources && sources.models.reduce((object, source) => ({ ...object, [source.id]: source }), {});

  const unique_webhook_ids = useMemo(
    () =>
      entries_list?.entries
        ? Array.from(new Set(entries_list.entries.map((entries) => entries.webhook_id))).sort()
        : [],
    [entries_list?.entries],
  );

  const { data: webhooks } = useSWR(
    unique_webhook_ids.length > 0 && APIMethodKeys.webhooks.list({ id: unique_webhook_ids }),
    () => HookdeckAPI.webhooks.list({ id: unique_webhook_ids }),
  );

  const webhooks_by_id =
    webhooks &&
    webhooks.models.reduce((object, webhook) => ({ ...object, [webhook.id]: webhook }), {});

  const loading =
    requests === undefined || !fetched || (unique_source_ids.length > 0 && sources === undefined);

  const has_request_filter = Object.values(filters.request || {}).some((v) => !!v);

  if (!loading && requests && count === 0) {
    return <RequestListEmptyView />;
  }

  const onRowSelected = (id: string) => {
    if (selected_request_id === id) {
      onRequestSelected(null);
    }

    return id
      ? id.indexOf(':') > 0 || id.indexOf('evt_') === 0
        ? onEntrySelected(String(id))
        : onRequestSelected(String(id))
      : onRequestSelected(null);
  };

  const widths = [{ max: 172, min: 172 }, { max: 222, min: 222 }, { min: 100 }];

  const headers = [`Request Date (${getCurrentTimezoneAbreviation()})`, 'Status', 'Source'];

  const rows = loading
    ? []
    : requests.reduce((rows, request) => {
        const outdated = outdated_ids.includes(request.id);
        return [
          ...rows,
          {
            id: request.id,
            selected: selected_request_id === request.id && !selected_entry_id,
            highlighted: selected_request_id === request.id,
            has_error: !!request.rejection_cause,
            expendable: true,
            outdated,
            fields: [
              request[order_by] && <DisplayDate date={request[order_by]} />,
              request.rejection_cause ? (
                <Div flex={{ gap: 2, justify: 'space-between', align: 'center' }}>
                  <Badge danger subtle icon="error" small>
                    {rejection_cause_labels[request.rejection_cause]}
                  </Badge>
                  <Button.Permission
                    role="member"
                    small
                    minimal
                    icon="retry"
                    muted
                    m={{ y: -1.5 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      retry(request.id);
                    }}
                  />
                </Div>
              ) : (
                <Div flex={{ gap: 2 }}>
                  <Badge monospace muted icon="success" small>
                    {view === 'cli' ? request.cli_events_count : request.events_count}
                  </Badge>
                  <Badge monospace muted icon="block" small>
                    {request.ignored_count}
                  </Badge>
                </Div>
              ),
              sources_by_id?.[request.source_id]?.name ? (
                <LabelButton
                  to={`/sources/${request.source_id}`}
                  monospace
                  neutral
                  label={sources_by_id?.[request.source_id]?.name}
                  small
                />
              ) : (
                <Text monospace size="s" muted>
                  Source deleted
                </Text>
              ),
            ],
          },
          ...((selected_request_id !== request.id || request.rejection_cause
            ? []
            : !entries_list?.fetched || (unique_webhook_ids.length > 0 && webhooks === undefined)
              ? [
                  {
                    id: 'entries-loading',
                    highlighted: true,
                    selectable: false,
                    element: (
                      <Div w={100} flex={{ justify: 'center' }} p={2.5}>
                        <Icon icon="loading" muted />
                      </Div>
                    ),
                  },
                ]
              : request.rejection_cause
                ? []
                : [
                    {
                      headers: ['Timestamp', 'Status', 'Affected Connection'],
                      rows: entries_list.entries.map((entry) => ({
                        id: entry.id,
                        selected: selected_entry_id === String(entry.id),
                        highlighted: selected_request_id === request.id,
                        fields: [
                          <DisplayDate
                            key={entry.id}
                            date={entry.created_at || request.created_at}
                          />,
                          'cause' in entry ? (
                            <Div key={entry.id} flex={{ justify: 'space-between', gap: 2 }}>
                              <Badge small icon="block" muted monospace>
                                {cause_filter_breakdown[entry.cause](entry).label}
                              </Badge>
                              <Div flex={{ align: 'center' }}>
                                <Button.Permission
                                  role="member"
                                  small
                                  minimal
                                  icon="retry"
                                  muted
                                  m={{ y: -1.5 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    retry(request.id, [entry.webhook_id]);
                                  }}
                                />
                              </Div>
                            </Div>
                          ) : (
                            <Badge small icon="success" muted monospace key={entry.id}>
                              Created Event
                            </Badge>
                          ),
                          !webhooks_by_id?.[entry.webhook_id] ? (
                            <Text size="s" muted>
                              Connection Deleted
                            </Text>
                          ) : (
                            <LabelButton
                              small
                              monospace
                              label={webhooks_by_id?.[entry.webhook_id]?.full_name}
                              neutral
                              to={`/connections/${entry.webhook_id}`}
                            />
                          ),
                        ],
                      })),
                    },
                  ]) || []),
          ...(selected_request_id === request.id &&
          view === 'cli' &&
          request.cli_events_count === 0 &&
          request.ignored_count === 0
            ? [
                {
                  id: 'cli-offline',
                  highlighted: true,
                  selectable: false,
                  element: (
                    <Div p={{ x: 4, y: 2 }}>
                      <Text muted size="s">
                        No events created because no CLI were online when the request was received.
                      </Text>
                    </Div>
                  ),
                },
              ]
            : []),
          ...(selected_request_id === request.id &&
          view === 'http' &&
          request.events_count === 0 &&
          request.ignored_count === 0 &&
          !request.rejection_cause
            ? [
                {
                  id: 'cli-offline',
                  highlighted: true,
                  selectable: false,
                  element: (
                    <Div p={{ x: 5, y: 1.5 }}>
                      <Text muted size="s">
                        No HTTP entries were created, try switching to the CLI view.
                      </Text>
                    </Div>
                  ),
                },
              ]
            : []),
          ...(selected_request_id === request.id &&
          entries_list?.entries &&
          entries_list?.has_next &&
          !entries_list?.validating &&
          selected_request_id === request.id
            ? [
                {
                  id: 'paging',
                  highlighted: true,
                  selectable: false,
                  element: (
                    <Div p={{ x: 5, y: 1.5 }}>
                      <Text muted size="s">
                        Displaying {entries_list.entries.length} of{' '}
                        {request.events_count + request.ignored_count!}.{' '}
                        <Link small to={`${view === 'cli' ? '/cli' : ''}/requests/${request.id}`}>
                          View all {'->'}
                        </Link>
                      </Text>
                    </Div>
                  ),
                },
              ]
            : []),
        ];
      }, []);

  return (
    <Table
      fill
      headers={headers}
      has_new_results={has_next_results}
      onLoadNewResults={refresh}
      widths={widths}
      rows={rows}
      loading={loading}
      loading_title={'Requests Loading'}
      loading_message={'It may take a few moments to populate your requests.'}
      onRowSelected={onRowSelected}
      onNextPage={
        next ? () => onPaginationChanged({ ...filters, next, prev: undefined }) : undefined
      }
      onPreviousPage={
        prev ? () => onPaginationChanged({ ...filters, prev, next: undefined }) : undefined
      }
      bottom_element={
        !loading &&
        !next &&
        !filters.date?.relative &&
        !filters.date?.min &&
        isBefore(new Date(team!.created_at), subDays(new Date(), subscription!.retention_days)) && (
          <Div p={3}>
            <Alert info inline w={100}>
              You've reached the end of your {subscription?.retention_days} days archival window.
              {!organization && (
                <Link to="/settings/organization/plans?highlight=retention_days" m={{ l: 2 }}>
                  Upgrade <Icon small icon="upgrade" />
                </Link>
              )}
            </Alert>
          </Div>
        )
      }
      current_count={count}
      total_count={has_request_filter ? undefined : total_count}
    />
  );
};

export default RequestsList;
