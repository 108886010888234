import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { IssueType } from '../../../../../../../../../typings/Issue.interface';
import IssueTrigger, {
  IssueTriggerBackpressureConfigs,
  IssueTriggerDeliveryConfigs,
  IssueTriggerIntegrationChannel,
  IssueTriggerSlackChannel,
  IssueTriggerTransformationConfigs,
} from '../../../../../../../../../typings/IssueTrigger.interface';
import {
  TeamIntegration,
  TeamIntegrationProvider,
  TeamIntegrationSlackConfigs,
} from '../../../../../../../../../typings/TeamIntegration.interface';
import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import team_integration_schemas from '../../../../../../configs/team-integration-schemas';
import field_formats from '../../../../../../utils/field-formatters';
import isEmpty from '../../../../../../utils/isEmpty';
import IntervalInput from '../../../../../common/Form/Fields/IntervalInput';
import MultiSelectInput from '../../../../../common/Form/Fields/MultiSelectInput';
import RadioGroupInput from '../../../../../common/Form/Fields/RadioInput';
import SelectInput from '../../../../../common/Form/Fields/SelectInput';
import TextInput from '../../../../../common/Form/Fields/TextInput';
import Alert from '../../../../../common/base/Alert';
import { StyledCard, StyledCardSection } from '../../../../../common/base/Card';
import Icon from '../../../../../common/base/Icon';
import Link from '../../../../../common/base/Link';
import Text from '../../../../../common/base/Text';
import { Div } from '../../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import SwitchInput from '../../../../../common/Form/Fields/SwitchInput';

type ReferenceOption = 'select' | 'pattern' | 'all';

interface ChannelValues {
  selected: boolean;
}

interface IntegrationValues extends ChannelValues {}

interface SlackValues extends IntegrationValues {
  channel_name: string;
}

interface EmailValues extends ChannelValues {
  emails: string | string[];
}

type Channels = Record<
  Exclude<TeamIntegrationProvider, 'datadog'>,
  SlackValues | IntegrationValues | EmailValues
>;

interface ChannelsValues {
  channels?: Channels;
}

interface DeliveryValues extends IssueTriggerDeliveryConfigs, ChannelsValues {
  reference: ReferenceOption;
  connections: string[];
  pattern: string;
}

interface TransformationValues extends IssueTriggerTransformationConfigs, ChannelsValues {
  reference: ReferenceOption;
  transformations: string[];
  pattern: string;
}

interface BackpressureValues extends IssueTriggerBackpressureConfigs, ChannelsValues {
  reference: ReferenceOption;
  destinations: string[];
  pattern: string;
}

export const team_channel_forms: Record<
  string,
  { getInitialValues; formatValuesToAPIInput; validate; Fields }
> = {
  slack: {
    getInitialValues: (channel: IssueTriggerSlackChannel) => {
      return {
        channel_name: channel.channel_name,
        selected: true,
      };
    },
    formatValuesToAPIInput: (values: SlackValues) => {
      return {
        channel_name: values.channel_name,
      };
    },
    validate: (values: SlackValues) => {
      if (!values?.channel_name) {
        return {
          channels: {
            slack: {
              channel_name: 'Required',
            },
          },
        };
      }
    },
    Fields: (integration: TeamIntegration) => {
      const { channels } = integration.configs as TeamIntegrationSlackConfigs;
      return (
        <>
          <SelectInput
            m={0}
            block
            search
            name={'channels.slack.channel_name'}
            placeholder={'Select channel...'}
            required
            options={
              channels?.map((channel) => ({
                label: channel.name,
                value: channel.name,
              })) || []
            }
          />
        </>
      );
    },
  },
  opsgenie: {
    getInitialValues: (channel: IssueTriggerIntegrationChannel) => {
      return {
        selected: true,
      };
    },
    formatValuesToAPIInput: (values: IntegrationValues) => {
      return {};
    },
    validate: undefined,
    Fields: undefined,
  },
  pagerduty: {
    getInitialValues: (channel: IssueTriggerIntegrationChannel) => {
      return {
        selected: true,
      };
    },
    formatValuesToAPIInput: (values: IntegrationValues) => {
      return {};
    },
    validate: undefined,
    Fields: undefined,
  },
  email: {
    getInitialValues: (channel: IssueTriggerIntegrationChannel) => {
      return {
        selected: true,
      };
    },
    formatValuesToAPIInput: (values: IntegrationValues) => {
      return {};
    },
    validate: undefined,
    Fields: undefined,
  },
  datadog: {
    getInitialValues: (channel: IssueTriggerIntegrationChannel) => {
      return {
        selected: true,
      };
    },
    formatValuesToAPIInput: (values: IntegrationValues) => {
      return {};
    },
    validate: undefined,
    Fields: undefined,
  },
};

const Channels = ({ integrations }: { integrations: TeamIntegration[] }) => {
  const { values } = useFormikContext() as any;

  const channels = values.channels || {};

  // Remove metrics type integrations and channels
  integrations = integrations.filter((integration) => {
    if (!integration.features.includes('notification')) {
      delete channels[integration.provider];
    }

    return integration.features.includes('notification');
  });

  return (
    <>
      <Div flex={{ align: 'center', justify: 'center' }} m={{ y: 4 }}>
        <Icon muted icon="arrow_downward" />
      </Div>
      <StyledCard>
        <StyledCardSection p={4}>
          <Text heading>Configure Notification Channels</Text>
          <Text muted>
            Specify the channels that should be notified when the issue is triggered.
          </Text>
        </StyledCardSection>
        <StyledCardSection p={4}>
          <StyledCard m={{ b: 2 }}>
            <StyledCardSection p={4}>
              <Div flex={{ justify: 'space-between' }}>
                <Text as={'p'} heading m={0}>
                  <Icon icon={'email'} left />
                  Email
                </Text>
                <SwitchInput name={`channels.email.selected`} m={0} />
              </Div>
            </StyledCardSection>
          </StyledCard>
          {integrations?.map((integration) => (
            <StyledCard m={{ b: 2 }} key={integration.id}>
              <StyledCardSection p={4}>
                <Div flex={{ justify: 'space-between' }}>
                  <Text as={'p'} heading m={0}>
                    <Icon icon={team_integration_schemas[integration.provider].icon} left />
                    {team_integration_schemas[integration.provider].label}
                  </Text>
                  <SwitchInput name={`channels.${integration.provider}.selected`} m={0} />
                </Div>
              </StyledCardSection>
              {channels[integration.provider]?.selected &&
                team_channel_forms[integration.provider].Fields && (
                  <StyledCardSection p={4}>
                    {team_channel_forms[integration.provider].Fields(integration)}
                  </StyledCardSection>
                )}
            </StyledCard>
          ))}
          {isEmpty(integrations) && (
            <Alert inline info m={{ t: 4 }}>
              Configure an{' '}
              <Link to={'/settings/project/integrations'} target={'_blank'}>
                Integration {'->'}
              </Link>{' '}
              to receive alerts in third-party platforms.
            </Alert>
          )}
        </StyledCardSection>
      </StyledCard>
    </>
  );
};

const getChannelsInitialValues = (
  integrations: TeamIntegration[],
  issue_trigger?: IssueTrigger,
) => {
  if (issue_trigger && !isEmpty(issue_trigger.channels)) {
    return Object.entries(issue_trigger.channels)?.reduce((prev, [provider, channel]) => {
      return {
        ...prev,
        [provider]: {
          ...team_channel_forms[provider].getInitialValues(channel),
        },
      };
    }, {} as any);
  }
  if (!issue_trigger) {
    return integrations.reduce(
      (prev, integration) => {
        return {
          ...prev,
          [integration.provider]: {
            selected: true,
          },
        };
      },
      {
        email: {
          selected: true,
        },
      } as any,
    );
  }
};

const formatChannelsValuesToAPIInput = (channels?: Channels) => {
  return Object.entries(channels || {})
    .filter(([, value]) => value.selected)
    .reduce((prev, [provider, value]) => {
      return {
        ...prev,
        [provider]: team_channel_forms[provider].formatValuesToAPIInput(value),
      };
    }, {} as any);
};

const validateChannels = (errors: any, channels: Channels) => {
  if (
    isEmpty(channels) ||
    isEmpty(Object.entries(channels).filter(([, channel]) => channel.selected))
  ) {
    return errors;
  } else {
    Object.entries(channels).forEach(([key, channel]) => {
      const { validate } = team_channel_forms[key];
      if (!validate || !channel.selected) {
        return;
      }
      errors = {
        ...errors,
        ...validate(channel),
      };
    });
  }
  return errors;
};

const issue_trigger_forms: Record<
  IssueType,
  { getInitialValues; formatValuesToAPIInput; Fields; validate? }
> = {
  delivery: {
    getInitialValues: (
      integrations: TeamIntegration[],
      issue_trigger?: IssueTrigger,
    ): DeliveryValues => {
      const configs = issue_trigger?.configs as IssueTriggerDeliveryConfigs;
      let reference = 'all';
      if (Array.isArray(configs?.connections)) {
        reference = 'select';
      } else if (configs?.connections !== '*' && configs?.connections.length > 0) {
        reference = 'pattern';
      }
      return {
        strategy: configs?.strategy || 'first_attempt',
        reference: reference as ReferenceOption,
        pattern: reference === 'pattern' ? (configs?.connections as string) : '',
        connections: reference === 'select' ? (configs.connections as string[]) : [],
        channels: getChannelsInitialValues(integrations, issue_trigger),
      };
    },
    validate: (values: {
      channels: Channels;
      channels_error: string;
    }): { channels?: Channels; channels_error?: string } => {
      let errors = {} as { channels?: Channels; channels_error?: string };
      errors = validateChannels(errors, values.channels);
      return errors;
    },
    formatValuesToAPIInput: (values: DeliveryValues) => {
      if (values.pattern && values.pattern.indexOf('*') === -1) {
        values.pattern = '*' + values.pattern + '*';
      }
      return {
        type: 'delivery',
        configs: {
          strategy: values.strategy,
          connections:
            values.reference === 'all'
              ? '*'
              : values.reference === 'pattern'
                ? values.pattern
                : values.connections,
        },
        channels: formatChannelsValuesToAPIInput(values.channels),
      };
    },
    Fields: ({ integrations }) => {
      const { HookdeckAPI } = useContext(GlobalContext);
      const { values } = useFormikContext<DeliveryValues>();

      const getLabel = (model) => model.full_name;

      const formatOptions = (data) =>
        data && data.models.map((model) => ({ label: getLabel(model), value: model.id }));

      return (
        <>
          <StyledCard>
            <StyledCardSection p={4}>
              <Text heading>Select Connections</Text>
              <Text muted>Connections on which the trigger should apply.</Text>
            </StyledCardSection>
            <StyledCardSection p={4}>
              <RadioGroupInput
                name="reference"
                inline
                options={[
                  { value: 'all', label: 'All connections' },
                  { value: 'select', label: 'Specific connections' },
                  { value: 'pattern', label: 'Matching pattern' },
                ]}
              />
              {values.reference === 'select' && (
                <MultiSelectInput
                  m={{ t: 4 }}
                  initial_options={[]}
                  option_title="Connections"
                  fetcher={HookdeckAPI.webhooks.list}
                  fetcherKey={APIMethodKeys.webhooks.list}
                  formatOptions={formatOptions}
                  formatFilters={(search_term?: string, values?: string[]) => ({
                    limit: 250,
                    full_name: search_term ? search_term : undefined,
                    id: values,
                  })}
                  name="connections"
                  required
                />
              )}
              {values.reference === 'pattern' && (
                <TextInput
                  m={{ t: 4 }}
                  name="pattern"
                  placeholder="prod-*"
                  help="Pattern matching your connection name, use * as wildcard"
                  format={field_formats.slugifyWithWildcard}
                  required
                />
              )}
            </StyledCardSection>
          </StyledCard>
          <Div flex={{ align: 'center', justify: 'center' }} m={{ y: 4 }}>
            <Icon muted icon="arrow_downward" />
          </Div>
          <StyledCard>
            <StyledCardSection p={4}>
              <Text heading>Choose Strategy</Text>
              <Text muted>Choose when the issue is first opened.</Text>
            </StyledCardSection>
            <StyledCardSection p={4}>
              <Div flex={{ align: 'center' }}>
                <Text m={{ r: 3 }}>Open issue after</Text>
                <SelectInput
                  m={0}
                  name="strategy"
                  options={[
                    { value: 'first_attempt', label: 'First Attempt' },
                    { value: 'final_attempt', label: 'Final Attempt' },
                  ]}
                />
              </Div>
            </StyledCardSection>
          </StyledCard>
          <Channels integrations={integrations} />
        </>
      );
    },
  },
  transformation: {
    getInitialValues: (
      integrations: TeamIntegration[],
      issue_trigger?: IssueTrigger,
    ): TransformationValues => {
      const configs = issue_trigger?.configs as IssueTriggerTransformationConfigs;
      let reference = 'all';
      if (Array.isArray(configs?.transformations)) {
        reference = 'select';
      } else if (configs?.transformations !== '*' && configs?.transformations.length > 0) {
        reference = 'pattern';
      }
      return {
        log_level: configs?.log_level || 'warn',
        reference: reference as ReferenceOption,
        pattern: reference === 'pattern' ? (configs?.transformations as string) : '',
        transformations: reference === 'select' ? (configs.transformations as string[]) : [],
        channels: getChannelsInitialValues(integrations, issue_trigger),
      };
    },
    validate: (values: {
      channels: Channels;
      channels_error: string;
    }): { channels?: Channels; channels_error?: string } => {
      let errors = {} as { channels?: Channels; channels_error?: string };
      errors = validateChannels(errors, values.channels);
      return errors;
    },
    formatValuesToAPIInput: (values: TransformationValues) => {
      return {
        type: 'transformation',
        configs: {
          log_level: values.log_level,
          transformations:
            values.reference === 'all'
              ? '*'
              : values.reference === 'pattern'
                ? values.pattern
                : values.transformations,
        },
        channels: formatChannelsValuesToAPIInput(values.channels),
      };
    },
    Fields: ({ integrations }) => {
      const { HookdeckAPI } = useContext(GlobalContext);
      const { values } = useFormikContext<DeliveryValues>();

      const formatOptions = (data) =>
        data && data.models.map((model) => ({ label: model.name, value: model.id }));

      return (
        <>
          <StyledCard>
            <StyledCardSection p={4}>
              <Text heading>Select Transformations</Text>
              <Text muted>Transformations on which the trigger should apply.</Text>
            </StyledCardSection>
            <StyledCardSection p={4}>
              <RadioGroupInput
                name="reference"
                inline
                options={[
                  { value: 'all', label: 'All transformations' },
                  { value: 'select', label: 'Specific transformations' },
                  { value: 'pattern', label: 'Matching pattern' },
                ]}
              />
              {values.reference === 'select' && (
                <MultiSelectInput
                  m={{ t: 4 }}
                  initial_options={[]}
                  option_title="Transformations"
                  fetcher={HookdeckAPI.transformations.list}
                  fetcherKey={APIMethodKeys.transformations.list}
                  formatFilters={(search_term?: string, values?: string[]) => ({
                    limit: 250,
                    name: search_term ? { contains: search_term } : undefined,
                    id: values,
                  })}
                  formatOptions={formatOptions}
                  name="transformations"
                  required
                />
              )}
              {values.reference === 'pattern' && (
                <TextInput
                  m={{ t: 4 }}
                  name="pattern"
                  placeholder="prod-*"
                  help="Pattern matching your transformation name, use * as wildcard"
                  format={field_formats.slugifyWithWildcard}
                  required
                />
              )}
            </StyledCardSection>
          </StyledCard>
          <Div flex={{ align: 'center', justify: 'center' }} m={{ y: 4 }}>
            <Icon muted icon="arrow_downward" />
          </Div>
          <StyledCard>
            <StyledCardSection p={4}>
              <Text heading>Choose Log Level</Text>
              <Text muted>Determine the minimum log level for which an issue are opened.</Text>
            </StyledCardSection>
            <StyledCardSection p={4}>
              <Div flex={{ align: 'center' }}>
                <Text m={{ r: 3 }}>Open issue on</Text>
                <SelectInput
                  m={0}
                  name="log_level"
                  options={[
                    { value: 'warn', label: 'Warn' },
                    { value: 'error', label: 'Error' },
                    { value: 'fatal', label: 'Fatal' },
                  ]}
                />
              </Div>
            </StyledCardSection>
          </StyledCard>
          <Channels integrations={integrations} />
        </>
      );
    },
  },
  backpressure: {
    getInitialValues: (
      integrations: TeamIntegration[],
      issue_trigger?: IssueTrigger,
    ): BackpressureValues => {
      const configs = issue_trigger?.configs as IssueTriggerBackpressureConfigs;
      let reference = 'all';
      if (Array.isArray(configs?.destinations)) {
        reference = 'select';
      } else if (configs?.destinations !== '*' && configs?.destinations.length > 0) {
        reference = 'pattern';
      }

      return {
        delay: configs?.delay || 1000 * 60 * 10,
        reference: reference as ReferenceOption,
        pattern: reference === 'pattern' ? (configs?.destinations as string) : '',
        destinations: reference === 'select' ? (configs.destinations as string[]) : [],
        channels: getChannelsInitialValues(integrations, issue_trigger),
      };
    },
    validate: (values: {
      delay: number;
      channels: Channels;
      channels_error: string;
    }): { delay?: string; channels?: Channels; channels_error?: string } => {
      let errors = {} as { delay?: string; channels?: Channels; channels_error?: string };
      if (values.delay < 1000 * 60) {
        errors.delay = 'Delay must be at least 1 minute';
      } else if (values.delay > 1000 * 60 * 60 * 24) {
        errors.delay = 'Delay must be at most 1 day';
      }
      errors = validateChannels(errors, values.channels);
      return errors;
    },
    formatValuesToAPIInput: (values: BackpressureValues) => {
      return {
        type: 'backpressure',
        configs: {
          delay: values.delay,
          destinations:
            values.reference === 'all'
              ? '*'
              : values.reference === 'pattern'
                ? values.pattern
                : values.destinations,
        },
        channels: formatChannelsValuesToAPIInput(values.channels),
      };
    },
    Fields: ({ integrations }) => {
      const { HookdeckAPI } = useContext(GlobalContext);
      const { values } = useFormikContext<DeliveryValues>();

      const formatOptions = (data) =>
        data && data.models.map((model) => ({ label: model.name, value: model.id }));

      return (
        <>
          <StyledCard>
            <StyledCardSection p={4}>
              <Text heading>Select Destinations</Text>
              <Text muted>Destinations on which the trigger should apply.</Text>
            </StyledCardSection>
            <StyledCardSection p={4}>
              <RadioGroupInput
                name="reference"
                inline
                options={[
                  { value: 'all', label: 'All destinations' },
                  { value: 'select', label: 'Specific destination' },
                  { value: 'pattern', label: 'Matching pattern' },
                ]}
              />
              {values.reference === 'select' && (
                <MultiSelectInput
                  m={{ t: 4 }}
                  initial_options={[]}
                  option_title="Destinations"
                  fetcher={HookdeckAPI.destinations.list}
                  fetcherKey={APIMethodKeys.destinations.list}
                  formatFilters={(search_term?: string, values?: string[]) => ({
                    limit: 250,
                    name: search_term ? { contains: search_term } : undefined,
                    id: values,
                  })}
                  formatOptions={formatOptions}
                  name="destinations"
                  required
                />
              )}
              {values.reference === 'pattern' && (
                <TextInput
                  m={{ t: 4 }}
                  name="pattern"
                  placeholder="prod-*"
                  help="Pattern matching your destination name, use * as wildcard"
                  format={field_formats.slugifyWithWildcard}
                  required
                />
              )}
            </StyledCardSection>
          </StyledCard>
          <Div flex={{ align: 'center', justify: 'center' }} m={{ y: 4 }}>
            <Icon muted icon="arrow_downward" />
          </Div>
          <StyledCard>
            <StyledCardSection p={4}>
              <Text heading>Choose Delay</Text>
              <Text muted>
                How long your oldest attempt will be delayed before the issue is opened.
              </Text>
            </StyledCardSection>
            <StyledCardSection p={4}>
              <Div flex={{ align: 'center' }}>
                <IntervalInput min_interval="minutes" name="delay" />
              </Div>
            </StyledCardSection>
          </StyledCard>
          <Channels integrations={integrations} />
        </>
      );
    },
  },
};

export default issue_trigger_forms;
