import { useField } from 'formik';
import styled, { css } from 'styled-components';

import { usePermissionAwareDisabled } from '../../../contexts/TeamPermissionContext';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import Text from '../../base/Text';

interface Props extends StyledUtilsProps {
  options: { value: string; label: string; description: string; disabled?: boolean }[];
  name: string;
}

const StyledRadioCard = styled(Div)<{
  disabled?: boolean;
  checked: boolean;
}>(
  ({ theme, disabled, checked }) => css`
    padding: ${theme.spacing(4)};
    margin: 0;
    flex-grow: 1;
    width: 100%;
    border: ${theme.border};
    border-radius: ${theme.radius.normal};
    box-shadow: ${theme.elevation[1]};

    ${checked &&
    css`
      border-color: ${theme.colors.outline.focus.primary};
    `}

    cursor: pointer;

    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
    ${disabled &&
    css`
      box-shadow: none;
      cursor: not-allowed;
      &:hover {
        cursor: not-allowed;
      }
    `}

    input {
      margin-right: ${theme.spacing(3)};
      float: left;

      appearance: none;
      background-color: #fff;

      min-width: ${theme.pxToRem(18)};
      min-height: ${theme.pxToRem(18)};
      border: 1px solid ${theme.colors.outline.neutral};
      border-radius: 50%;
      display: grid;
      place-content: center;
      background-color: ${theme.colors.surface.base.surface};

      &:hover:not(:disabled) {
        border-color: ${theme.colors.outline.hover.neutral};
        cursor: pointer;
      }

      &:checked:not(:disabled) {
        border-color: ${theme.colors.outline.primary};
        background-color: ${theme.colors.surface.base.primary};
      }

      &:disabled {
        border: 1px solid ${theme.colors.outline.neutral};
        background-color: ${theme.colors.surface.base.disabled};
        color: ${theme.colors.on.neutral.secondary_neutral};
        cursor: not-allowed;
      }

      &:disabled + span {
        color: ${theme.colors.on.neutral.secondary_neutral};
      }

      &:checked {
        &::before {
          content: '';
          height: 8px;
          width: 8px;
          background-color: ${disabled
            ? theme.colors.on.neutral.disabled
            : theme.colors.on.hue.primary};
          border-radius: 50%;
        }
      }
    }

    ${!disabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.surface.base.background};
      }
    `}
  `,
);

const RadioCardsInput: React.FC<Props> = ({ options, name, ...props }) => {
  const field_disabled = usePermissionAwareDisabled();
  const [field] = useField(name);

  return (
    <div role="group">
      <Div {...props} flex={{ align: 'center', gap: 4 }}>
        {options.map(({ value, label, disabled, description }) => (
          <StyledRadioCard
            key={value}
            as="label"
            disabled={disabled}
            checked={field.value === value}>
            <input
              {...field}
              checked={field.value === value}
              value={value}
              name={name}
              disabled={field_disabled || disabled}
              type="radio"
            />
            <Text subtitle m={{ b: 1 }} muted={disabled}>
              {label}
            </Text>
            <Text muted>{description}</Text>
          </StyledRadioCard>
        ))}
      </Div>
    </div>
  );
};

export default RadioCardsInput;
