import { useContext } from 'react';
import { capitalizeFirstLetter, fieldName } from '../../../../../utils';
import { showChat } from '../../../../../utils/liveChat';
import Icon, { IconName } from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Text from '../../../../common/base/Text';
import ItemListPickerInput from '../../../../common/Form/Fields/ItemListPicker';
import { StyledField } from '../../../../common/Form/Fields/SelectInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { DashboardContext } from '../../DashboardContext';

export function SelectDestinationType({ prefix }: { prefix: string }) {
  const { destination_types } = useContext(DashboardContext);

  return (
    <StyledField large block>
      <Div flex={{ justify: 'space-between', align: 'center' }}>
        <label>
          <Text size="s" subtitle>
            Destination Type
            <Text danger as="span">
              *
            </Text>
          </Text>
        </label>
      </Div>
      <ItemListPickerInput
        search_placeholder="Search for a destination provider..."
        name={fieldName('type', prefix)}
        categories={[]}
        outline
        getItemsFooter={() => (
          <Text p={{ y: 2, l: 3 }} m={0} size="s" as="p" muted>
            Looking for more protocols?{' '}
            <Link neutral icon={'link'} onClick={showChat}>
              Request a new destination type
            </Link>
          </Text>
        )}
        show_category_badge={false}
        getItemsKey={(search_team, category) => `destination-types-${search_team}-${category}`}
        getItems={(search_term, category) =>
          Promise.resolve(
            // Sort first based on category, then by label
            Object.entries(destination_types!)
              .filter(([, config]) => {
                if (
                  search_term &&
                  !config.label.toLowerCase().includes(search_term.toLowerCase())
                ) {
                  return false;
                }
                if (category === 'all' && ['aws', 'generic'].includes(config.category)) {
                  return true;
                }

                return category === config.category;
              })
              .map(([key, config]) => ({
                id: key,
                icon: (config.icon || ('destination' as const)) as IconName,
                label: config.label,
                description: config.description,
                category: capitalizeFirstLetter(config.category),
              }))
              .sort((a, b) => {
                if (a.category === b.category) {
                  return a.label.localeCompare(b.label);
                }
                return b.category.localeCompare(a.category);
              }),
          )
        }
      />
      <Text m={{ t: 1, b: 0 }} size="s" as="p" muted>
        <Icon icon="info" left={1} />
        Represents the consumer of your HTTP requests.
      </Text>
    </StyledField>
  );
}
