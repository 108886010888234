import { useField } from 'formik';

import { fieldName } from '../../../../../utils';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import CheckboxInput from '../../../../common/Form/Fields/CheckboxInput';
import EditorInput from '../../../../common/Form/Fields/EditorInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import DropdownMenu from '../../../../common/DropdownMenu';
import LINKS from '../../../../../configs/links';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import { Grid, GridUnit } from '@hookdeck/theme';
import TextAreaInput from '../../../../common/Form/Fields/TextAreaInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { useContext, useMemo } from 'react';
import {
  SourceType,
  SourceTypeFeatures,
  DestinationTypeFeatures,
  TypeFeatureFields,
  isSourceTypeFeatureUnmanaged,
} from '../../../../../../../../typings/Integration.interface';
import { DashboardContext } from '../../DashboardContext';

export function useFeatures(features: SourceTypeFeatures | DestinationTypeFeatures, prefix = '') {
  return useMemo(() => {
    const default_features = {
      required_features: [] as Features,
      advanced_features: [] as Features,
    };

    return Object.entries(features).reduce((acc, [type, config]) => {
      if (config.managed) return acc;

      if (type === 'VERIFICATION') {
        config.fields?.forEach((field) => {
          field.name = fieldName(field.name, prefix);
        });
      }

      const feature = { ...config, type: type as keyof DestinationTypeFeatures };
      if (config.required || config.advanced === false) {
        acc.required_features.push(feature);
      } else {
        acc.advanced_features.push(feature);
      }

      return acc;
    }, default_features);
  }, [features]);
}

function calculateMargin(features: TypeFeatureFields, index: number) {
  return features.length === 1
    ? { t: 0 }
    : {
        r: index % 2 === 0 ? 2 : 0,
        l: index % 2 !== 0 ? 2 : 0,
        t: features.length === 2 ? 0 : 2,
      };
}

function VerificationFields({
  verification_features,
  prefix,
}: {
  verification_features: TypeFeatureFields;
  prefix: string;
}) {
  return !!verification_features && verification_features.length > 0 ? (
    <Div flex={{ wrap: true }} m={{ t: 2 }} style={{ rowGap: '8px' }}>
      {verification_features.map((field, i) => {
        switch (field.type) {
          case 'select':
            return (
              <GridUnit size={verification_features.length === 1 ? 1 : 1 / 2} key={field.name}>
                <SelectInput
                  m={calculateMargin(verification_features, i)}
                  block
                  label={field.label}
                  name={fieldName(field.name, prefix)}
                  options={field.options ?? []}
                  required={field.required === false ? false : true}
                />
              </GridUnit>
            );
          case 'textarea':
            return (
              <GridUnit size={1} key={field.name}>
                <TextAreaInput
                  m={calculateMargin(verification_features, i)}
                  monospace={true}
                  label={field.label}
                  placeholder={field.placeholder}
                  default_value={''}
                  name={fieldName(field.name, prefix)}
                  required={field.required === false ? false : true}
                />
              </GridUnit>
            );
          default:
            return (
              <GridUnit size={verification_features.length === 1 ? 1 : 1 / 2} key={field.name}>
                <TextInput
                  m={calculateMargin(verification_features, i)}
                  label={field.label}
                  type={field.type}
                  placeholder={field.placeholder}
                  default_value={''}
                  name={fieldName(field.name, prefix)}
                  required={field.required === false ? false : true}
                />
              </GridUnit>
            );
        }
      })}
    </Div>
  ) : null;
}

function VerificationFeature({ feature, prefix }: { feature: FeatureType; prefix: string }) {
  const { organization } = useContext(DashboardContext);
  const [{ value: type }] = useField<SourceType>(fieldName('type', prefix));
  const [{ value: verification_type }] = useField<SourceType>(fieldName('config.type', prefix));
  const [{ value: enable_verification }] = useField<boolean>(
    fieldName('enable_verification', prefix),
  );

  const custom_auth = isSourceTypeFeatureUnmanaged(feature) && feature?.auth;
  const field_path = 'config.auth';
  const verification_features =
    type && !!custom_auth
      ? custom_auth[verification_type]?.fields
      : isSourceTypeFeatureUnmanaged(feature) && feature?.fields
        ? feature.fields
        : undefined;

  if (custom_auth) {
    return (
      <Div m={{ y: 4 }}>
        <StyledCard overflow_hidden dashed={!enable_verification}>
          <StyledCardSection p={2}>
            <Div flex={{ justify: 'flex-start', align: 'center', gap: 4 }} p={1}>
              <SwitchInput name={fieldName('enable_verification', prefix)} />
              <Text size="s" subtitle muted={!enable_verification} flex={{ align: 'center' }}>
                Authenticate
                <Tooltip
                  tooltip="Enable authentication to verify the authenticity of the request made to Hookdeck. A request that fails verification will be logged, but not delivered."
                  cta={{
                    label: 'Learn more',
                    to: LINKS.product_docs.sources_add_auth,
                  }}>
                  <Icon muted right small pointer icon="info" />
                </Tooltip>
              </Text>
            </Div>
          </StyledCardSection>
          <StyledCardSection borderless={!enable_verification}>
            {enable_verification && (
              <StyledCard overflow_hidden borderless>
                <StyledCardSection p={3}>
                  {custom_auth && (
                    <SelectInput
                      m={0}
                      block
                      required
                      label="Authentication Method"
                      name={fieldName('config.type', prefix)}
                      options={Object.entries(custom_auth)
                        .filter(([key]) => {
                          if (
                            !organization?.feature_flags?.oauth2_authorization_code &&
                            key === 'OAUTH2_AUTHORIZATION_CODE'
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map(([key, config]) => ({
                          value: key,
                          label: config.label,
                        }))}
                    />
                  )}
                  <VerificationFields
                    verification_features={verification_features}
                    prefix={fieldName(field_path, prefix)}
                  />
                </StyledCardSection>
              </StyledCard>
            )}
          </StyledCardSection>
        </StyledCard>
      </Div>
    );
  }
  if (isSourceTypeFeatureUnmanaged(feature) && feature?.required) {
    return (
      <VerificationFields
        verification_features={verification_features}
        prefix={fieldName(field_path, prefix)}
      />
    );
  }

  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden dashed={!enable_verification}>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'flex-start', align: 'center', gap: 4 }} p={1}>
            <SwitchInput name={fieldName('enable_verification', prefix)} />
            <Text size="s" subtitle muted={!enable_verification} flex={{ align: 'center' }}>
              Authenticate
              <Tooltip
                tooltip="Enable authentication to verify the authenticity of the request made to Hookdeck. A request that fails verification will be logged, but not delivered."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.sources_add_auth,
                }}>
                <Icon muted right small pointer icon="info" />
              </Tooltip>
            </Text>
          </Div>
        </StyledCardSection>
        <StyledCardSection
          borderless={
            !enable_verification || !verification_features || verification_features.length === 0
          }>
          {enable_verification && verification_features && verification_features.length > 0 && (
            <StyledCard overflow_hidden borderless>
              <StyledCardSection p={{ x: 3, b: 3, t: 1 }}>
                <VerificationFields
                  verification_features={verification_features}
                  prefix={fieldName(field_path, prefix)}
                />
              </StyledCardSection>
            </StyledCard>
          )}
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

function CustomHTTPFeature({ feature, prefix }: { feature: FeatureType; prefix: string }) {
  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <Text size="s" subtitle flex={{ align: 'center' }}>
              HTTP Methods
              <Tooltip
                tooltip="Allow only specific HTTP methods to be accepted by Hookdeck. Requests that don't match the allowed HTTP will be logged."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.sources_custom_methods,
                }}>
                <Icon muted right pointer icon="info" />
              </Tooltip>
            </Text>
            <Div flex={{ gap: 2 }}>
              {['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].map((method) => (
                <StyledCard p={{ x: 2, y: 1 }} key={method}>
                  <CheckboxInput
                    m={0}
                    label={method}
                    name={fieldName(`config.allowed_http_methods.${method}`, prefix)}
                  />
                </StyledCard>
              ))}
            </Div>
          </Div>
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

export type CustomResponseValue = {
  content_type: 'json' | 'text' | 'xml';
  body: string;
};

function CustomResponseFeature({ prefix }: { feature: FeatureType; prefix: string }) {
  const [{ value: enable_custom_response }] = useField<boolean>(
    fieldName('enable_custom_response', prefix),
  );
  const [{ value: custom_response }] = useField<CustomResponseValue>(
    fieldName('config.custom_response', prefix),
  );
  const [{ value: custom_response_content_type }, , { setValue: setCustomResponseContentType }] =
    useField<'text' | 'json' | 'xml'>(fieldName('config.custom_response.content_type', prefix));

  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden dashed={!enable_custom_response}>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <Div flex={{ gap: 4, align: 'center' }} p={1}>
              <SwitchInput name={fieldName('enable_custom_response', prefix)} />
              <Text size="s" subtitle muted={!enable_custom_response} flex={{ align: 'center' }}>
                Customize Response
                <Tooltip
                  tooltip="Override the default Hookdeck HTTP response with a custom static response in JSON, XML or TXT"
                  cta={{
                    label: 'Learn more',
                    to: LINKS.product_docs.sources_customize_response,
                  }}>
                  <Icon muted right small pointer icon="info" />
                </Tooltip>
              </Text>
            </Div>
            <Div flex={{ gap: 2, align: 'center' }}>
              {enable_custom_response && (
                <DropdownMenu
                  minimal
                  small
                  p={0}
                  label={custom_response_content_type.toUpperCase()}
                  options={[
                    { label: 'JSON', onClick: () => setCustomResponseContentType('json') },
                    {
                      label: 'TEXT',
                      onClick: () => setCustomResponseContentType('text'),
                    },
                    {
                      label: 'XML',
                      onClick: () => setCustomResponseContentType('xml'),
                    },
                  ]}
                />
              )}
            </Div>
          </Div>
        </StyledCardSection>
        <StyledCardSection borderless={!enable_custom_response}>
          {enable_custom_response && (
            <StyledCard overflow_hidden borderless>
              <EditorInput
                name={fieldName('config.custom_response.body', prefix)}
                height="164px"
                language={custom_response.content_type === 'json' ? 'json' : 'text'}
              />
            </StyledCard>
          )}
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

function CustomUrlFeature({ prefix }: { feature: FeatureType; prefix: string }) {
  return (
    <Div m={{ y: 4 }}>
      <TextInput
        name={fieldName('config.url', prefix)}
        label="Destination URL"
        placeholder={`https://example.com`}
        help="The endpoint Hookdeck will route your HTTP requests to."
        required
        monospace
        m={0}
        w={100}
      />
    </Div>
  );
}

function CustomPathForwardingFeature({
  feature,
  prefix,
}: {
  feature: FeatureType;
  prefix: string;
}) {
  const [{ value: path_forwarding_disabled }] = useField<boolean>(
    fieldName('config.path_forwarding_disabled', prefix),
  );

  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden dashed={!path_forwarding_disabled}>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'space-between', align: 'center' }} p={1}>
            <Div flex={{ gap: 4, align: 'center' }}>
              <SwitchInput name={fieldName('config.path_forwarding_disabled', prefix)} />
              <Text size="s" subtitle muted={!path_forwarding_disabled} flex={{ align: 'center' }}>
                Path forwarding
                <Tooltip
                  tooltip="Configure if the request path should be carried over and appended to your destination path."
                  cta={{
                    label: 'Learn more',
                    to: LINKS.product_docs.destinations_advanced_edit,
                  }}>
                  <Icon muted right small pointer icon="info" />
                </Tooltip>
              </Text>
            </Div>
          </Div>
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

function CustomDestinationHTTPMethodFeature({
  feature,
  prefix,
}: {
  feature: FeatureType;
  prefix: string;
}) {
  const [{ value: enable_custom_http_method }] = useField<boolean>(
    fieldName('enable_custom_http_method', prefix),
  );
  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden dashed={!enable_custom_http_method}>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'space-between', align: 'center' }} p={1}>
            <Div flex={{ gap: 4, align: 'center' }}>
              <SwitchInput name={fieldName('enable_custom_http_method', prefix)} />
              <Text size="s" subtitle muted={!enable_custom_http_method} flex={{ align: 'center' }}>
                Customize HTTP Method
                <Tooltip
                  tooltip="Force the requests to your destination to use a specific HTTP method. By default the request will be made with the same method as the original request."
                  cta={{
                    label: 'Learn more',
                    to: LINKS.product_docs.destinations_advanced_edit,
                  }}>
                  <Icon muted right small pointer icon="info" />
                </Tooltip>
              </Text>
            </Div>
          </Div>
        </StyledCardSection>
        <StyledCardSection borderless={!enable_custom_http_method}>
          {enable_custom_http_method && (
            <StyledCard overflow_hidden borderless>
              <StyledCardSection p={3}>
                <SelectInput
                  block
                  m={0}
                  name={fieldName('config.http_method', prefix)}
                  label="HTTP Method"
                  required
                  default_value="POST"
                  options={[
                    { value: 'GET', label: 'GET' },
                    { value: 'POST', label: 'POST' },
                    { value: 'PUT', label: 'PUT' },
                    { value: 'PATCH', label: 'PATCH' },
                    { value: 'DELETE', label: 'DELETE' },
                  ]}
                />
              </StyledCardSection>
            </StyledCard>
          )}
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

function MaxThroughputRateFeature({ feature, prefix }: { feature: FeatureType; prefix: string }) {
  const [{ value: enabled_rate_limit }] = useField<boolean>(fieldName('enable_rate_limit', prefix));
  const { team } = useContext(DashboardContext);

  return (
    <Div m={{ y: 4 }}>
      <StyledCard overflow_hidden dashed={!enabled_rate_limit}>
        <StyledCardSection p={2}>
          <Div flex={{ justify: 'flex-start', align: 'center', gap: 4 }} p={1}>
            <SwitchInput name={fieldName('enable_rate_limit', prefix)} />
            <Text size="s" subtitle muted={!enabled_rate_limit} flex={{ align: 'center' }}>
              Max delivery rate
              <Tooltip
                tooltip="Enable events delivery rate to control the maximum throughput of events delivered to your destination."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.destinations_max_delivery_rate,
                }}>
                <Icon muted right small pointer icon="info" />
              </Tooltip>
            </Text>
          </Div>
        </StyledCardSection>
        <StyledCardSection borderless={!enabled_rate_limit}>
          {enabled_rate_limit && (
            <StyledCard overflow_hidden borderless>
              <StyledCardSection p={3}>
                <Grid>
                  <GridUnit size={1 / 2}>
                    <TextInput
                      m={{ r: 2, b: 0 }}
                      name={fieldName('config.rate_limit', prefix)}
                      label="Rate"
                      default_value={
                        team?.max_events_per_second ? team?.max_events_per_second.toString() : '5'
                      }
                      type="number"
                      min={1}
                      required
                    />
                  </GridUnit>
                  <GridUnit size={1 / 2}>
                    <SelectInput
                      m={{ l: 2, b: 0 }}
                      block
                      name={fieldName('config.rate_limit_period', prefix)}
                      label="Time Interval"
                      options={[
                        {
                          value: 'second',
                          label: 'per second',
                        },
                        {
                          value: 'minute',
                          label: 'per minute',
                        },
                        {
                          value: 'hour',
                          label: 'per hour',
                        },
                        {
                          value: 'concurrent',
                          label: 'concurrent',
                        },
                      ]}
                      required
                    />
                  </GridUnit>
                </Grid>
              </StyledCardSection>
            </StyledCard>
          )}
        </StyledCardSection>
      </StyledCard>
    </Div>
  );
}

export type FeatureType = (
  | SourceTypeFeatures['VERIFICATION']
  | DestinationTypeFeatures['VERIFICATION']
) & { type: keyof SourceTypeFeatures | keyof DestinationTypeFeatures };
export type Features = Array<FeatureType>;
export function Feature({ feature, prefix }: { feature: FeatureType; prefix: string }) {
  switch (feature.type) {
    case 'HANDSHAKE':
    case 'VERIFICATION':
      return <VerificationFeature feature={feature} prefix={prefix} />;
    case 'CUSTOM_HTTP_METHOD':
      return <CustomHTTPFeature feature={feature} prefix={prefix} />;
    case 'CUSTOM_RESPONSE':
      return <CustomResponseFeature feature={feature} prefix={prefix} />;
    case 'CUSTOM_URL':
      return <CustomUrlFeature feature={feature} prefix={prefix} />;
    case 'CUSTOM_PATH_FORWARDING':
      return <CustomPathForwardingFeature feature={feature} prefix={prefix} />;
    case 'CUSTOM_DESTINATION_HTTP_METHOD':
      return <CustomDestinationHTTPMethodFeature feature={feature} prefix={prefix} />;
    case 'MAX_THROUGHPUT_RATE':
      return <MaxThroughputRateFeature feature={feature} prefix={prefix} />;
    default:
      return null;
  }
}
