import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useSWR, { KeyedMutator } from 'swr';

import { APIList } from '../../../../../../../../typings/API.interface';
import {
  TeamIntegration,
  TeamIntegrationProvider,
} from '../../../../../../../../typings/TeamIntegration.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import team_integration_schemas, {
  TeamIntegrationSchema,
} from '../../../../../configs/team-integration-schemas';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import { OverlayLoading } from '../../../../common/base/Loading';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import { useDialog } from '../../../../common/Dialog';
import CheckboxInput from '../../../../common/Form/Fields/CheckboxInput';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import Modal from '../../../../common/Modal';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import { DashboardContext } from '../../DashboardContext';
import { team_channel_forms } from '../../IssueTriggers/IssueTriggersList/Forms/issue_triggers_forms';
import Divider from '../../../../common/base/Divider';
import Link from '../../../../common/base/Link';
import { showChat } from '../../../../../utils/liveChat';
import Alert from '../../../../common/base/Alert';
import RadioGroupInput from '../../../../common/Form/Fields/RadioInput';

interface IntegrationModalProps {
  integration?: TeamIntegration;
  provider: TeamIntegrationProvider;
  onSubmit: (integration: TeamIntegration) => void;
}

const IntegrationModal: React.FC<IntegrationModalProps> = ({ integration, provider, onSubmit }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const history = useHistory();
  const { addToast } = useToasts();
  const [validation_error, setValidationError] = useState<React.ReactNode>(null);
  const { query } = useSearchQuery<{ code: string }>();

  const { label, fields, how_steps, callback, alert_banner } = team_integration_schemas[provider];
  const { validate, Fields: ChannelFields } = team_channel_forms[provider];

  const handleClose = () => {
    history.push({
      pathname: '/settings/project/integrations',
      state: { scroll: false },
    });
  };

  const handleVerification = (values) => {
    setValidationError(null);
    return HookdeckAPI.team_integrations
      .create({ provider, ...values })
      .then(async (integration) => {
        onSubmit(integration);
      })
      .catch((e) => {
        if (e.response.code === 'BAD_REQUEST') {
          setValidationError(
            <Text danger>
              Verification failed for provider{' '}
              <Text as={'span'} heading>
                {team_integration_schemas[provider].label}
              </Text>
              , please check your configuration and make sure the permissions meet the minimum
              requirements.
            </Text>,
          );
        }
        addToast('error', 'Failed to create your integration, try again!');
      });
  };

  const handleFinish = (values) => {
    if (values.attach_all) {
      delete values.attach_all;
      const channel_values = ChannelFields ? values.channels[provider] : {};
      return HookdeckAPI.issue_triggers
        .attachChannelsToAll({
          [provider]: channel_values,
        })
        .then(async () => {
          handleClose();
          addToast('success', 'Integration attached');
        })
        .catch((e) => {
          addToast('error', 'Failed to attach integration, try again!');
        });
    }
    handleClose();
  };

  const getModalField = (field) => {
    const { type, name, label, options, required, placeholder, ...rest } = field;
    switch (type) {
      case 'select':
        return (
          <SelectInput
            block
            key={name}
            label={label}
            name={`configs.${name}`}
            options={options}
            required={required === false ? false : true}
            {...rest}
          />
        );
      case 'radio':
        return (
          <Div m={{ bottom: 4 }}>
            <RadioGroupInput
              key={name}
              label={label}
              name={`configs.${name}`}
              options={options}
              required={required === false ? false : true}
              {...rest}
            />
          </Div>
        );
      default:
        return (
          <TextInput
            key={name}
            label={label}
            type={type}
            placeholder={placeholder}
            default_value={''}
            name={`configs.${name}`}
            required={required === false ? false : true}
            {...rest}
          />
        );
    }
  };

  useEffect(() => {
    if (!integration && callback) {
      HookdeckAPI.team_integrations
        .create({ provider, configs: callback(query) })
        .then(async (integration) => {
          onSubmit(integration);
        })
        .catch(() => {
          addToast('error', `Failed to connect ${label}, try again!`);
          history.push('/settings/project/integrations');
        });
    }
  }, []);

  if (!integration && callback) {
    return <OverlayLoading />;
  }

  return (
    <>
      {integration ? (
        integration.features.includes('notification') ? (
          <Formik
            initialValues={{ attach_all: true, channels: {} }}
            validate={(values) => {
              if (!values?.attach_all || !ChannelFields) {
                return;
              }
              return validate(values?.channels[provider!]);
            }}
            onSubmit={handleFinish}>
            {(props) => (
              <Form>
                <Modal
                  title={`${label} Connected`}
                  title_icon={'success_circle'}
                  icon_theme={{ success: true }}
                  submit_label={'Confirm'}
                  cancel_label={'Cancel'}
                  onSubmit={props.handleSubmit}
                  onCancel={handleClose}
                  onClose={handleClose}
                  is_submitting={props.isSubmitting}>
                  <Text subtitle as="p">
                    Attach to Issue Triggers
                  </Text>
                  <CheckboxInput
                    m={{ b: 4 }}
                    name={'attach_all'}
                    label={'Attach to all issue triggers'}
                    help={'This will attach this integration to all issue triggers.'}
                  />
                  {ChannelFields && (
                    <>
                      <Text subtitle as="p">
                        Configuration
                      </Text>
                      {alert_banner && (
                        <Alert info inline m={{ b: 4 }}>
                          {alert_banner}
                        </Alert>
                      )}
                      {ChannelFields(integration)}
                    </>
                  )}
                </Modal>
              </Form>
            )}
          </Formik>
        ) : null
      ) : (
        <Formik initialValues={{ attach_all: true }} onSubmit={handleVerification}>
          {(props) => (
            <Form>
              <Modal
                title={`Connect ${label}`}
                submit_label={'Connect'}
                cancel_label={'Cancel'}
                onSubmit={props.handleSubmit}
                onCancel={handleClose}
                onClose={handleClose}
                is_submitting={props.isSubmitting}>
                {how_steps && (
                  <>
                    <Text subtitle as="p">
                      Instructions
                    </Text>
                    <ol>
                      {how_steps?.map((step, i) => (
                        <li
                          key={i}
                          dangerouslySetInnerHTML={{
                            __html: step,
                          }}
                        />
                      ))}
                    </ol>
                  </>
                )}
                <Text subtitle as="p">
                  Configuration
                </Text>
                {provider && fields?.map((field) => getModalField(field))}
                <Text as={'p'} muted>
                  All secrets are AES encrypted.
                </Text>
                {validation_error}
              </Modal>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const IntegrationCard: React.FC<{
  provider: TeamIntegrationProvider;
  schema: TeamIntegrationSchema;
  integration?: TeamIntegration;
  mutate?: KeyedMutator<APIList<TeamIntegration>>;
}> = ({ provider, schema, integration, mutate }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();

  let allow = false;
  if (
    subscription!.features?.includes('all_integrations') &&
    schema?.features.includes('notification')
  ) {
    allow = true;
  }
  if (subscription!.features?.includes('metrics_export') && schema?.features.includes('metrics')) {
    allow = true;
  }

  const button_action = schema.fields
    ? {
        as: RouterLink,
        to: (location) => ({
          ...location,
          pathname: `/settings/project/integrations/${provider}/connect`,
          state: { scroll: false },
        }),
      }
    : {
        onClick: () => {
          schema.onClick?.();
        },
      };

  const disconnect = async (id: string) => {
    try {
      await HookdeckAPI.team_integrations.delete(id);
      mutate?.((integrations) => {
        if (integrations) {
          integrations.models = integrations.models.filter((m) => m.id !== id);
          integrations.count--;
        }
        return integrations;
      });
      addToast('success', 'Integration disconnected');
    } catch (err) {
      addToast('error', err.message);
    }
  };

  const handleDisconnect = (id: string, provider: string) => {
    showDialog(
      () => disconnect(id),
      () => null,
      {
        title: `Disconnect ${team_integration_schemas[provider]?.label}`,
        message: (
          <Text>
            Are you sure you want to disconnect{' '}
            <Text as={'span'} heading>
              {team_integration_schemas[provider]?.label}
            </Text>
            ? You will no longer receive notifications for this integration.
          </Text>
        ),
        submit_label: 'Disconnect',
        cancel_label: 'Cancel',
        danger: true,
      },
    );
  };

  return (
    <StyledCard p={{ y: 2, x: 3 }}>
      <StyledCardSection flex={{ justify: 'space-between', align: 'center' }}>
        <Text as={'p'} heading m={0}>
          <Icon icon={schema.icon} small m={{ r: 2 }} />
          {schema.label}
          {integration && (
            <Badge subtle success m={{ l: 2 }}>
              Connected
            </Badge>
          )}
        </Text>
        {integration ? (
          <Button.Permission
            onClick={() => handleDisconnect(integration.id, integration.provider)}
            outline
            danger
            icon="disconnect">
            Disconnect
          </Button.Permission>
        ) : !allow ? (
          <Tooltip
            tooltip="Upgrade plan to enable integrations."
            placement="bottom-end"
            align="left"
            cta={{
              icon: 'upgrade',
              label: 'Upgrade',
              to: '/settings/organization/plans?highlight=all_integrations',
            }}>
            <Button as={'div'} disabled outline icon="connect">
              Connect
            </Button>
          </Tooltip>
        ) : (
          <Button.Permission {...button_action} outline icon="connect">
            Connect
          </Button.Permission>
        )}
      </StyledCardSection>
    </StyledCard>
  );
};

const ConfigureStepModal: React.FC<{
  provider: TeamIntegrationProvider;
}> = ({ provider }) => {
  const history = useHistory();
  const schema = team_integration_schemas[provider];

  const { query } = useSearchQuery<{ [key: string]: string }>();

  useEffect(() => {
    if (schema.configure_step.callback) {
      schema.configure_step.callback(query);
    }
  }, []);

  return (
    <Modal
      title={schema.configure_step.label}
      icon_theme={{ success: true }}
      onClose={() => {
        history.push('/settings/project/integrations', { scroll: false });
      }}>
      <Text subtitle as="p">
        {schema.configure_step.description}
      </Text>
      <Button as={'a'} icon="link" href={schema.configure_step.button_href}>
        {schema.configure_step.button_text}
      </Button>
    </Modal>
  );
};

const TeamIntegrations: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();

  const { data: integrations, mutate } = useSWR(APIMethodKeys.team_integrations.list(), () =>
    HookdeckAPI.team_integrations.list(),
  );

  const not_connected_integrations = Object.entries(team_integration_schemas).filter(
    ([key]) => (integrations?.models.findIndex((i) => i.provider === key) ?? -1) < 0,
  );

  return (
    <>
      <Route
        path={['/settings/project/integrations/:provider/configure']}
        render={({ match }) => (
          <ConfigureStepModal provider={match.params.provider as TeamIntegrationProvider} />
        )}
      />
      <Route
        path={['/settings/project/integrations/:provider/connect']}
        render={({ match }) => (
          <IntegrationModal
            integration={integrations?.models.find((i) => i.provider === match.params.provider)}
            provider={match.params.provider as TeamIntegrationProvider}
            onSubmit={(integration) => {
              mutate(
                (integrations) => ({
                  ...integrations!,
                  count: integrations!.count + 1,
                  models: [integration, ...integrations!.models],
                }),
                false,
              );
              addToast('success', 'Integration connected');
            }}
          />
        )}
      />
      <Text heading as="h2" m={{ b: 2 }}>
        Integrations
      </Text>
      <Divider m={{ b: 4 }} />
      <Div flex={{ direction: 'column', gap: 3 }}>
        {integrations?.models.map((integration) => (
          <IntegrationCard
            key={integration.id}
            provider={integration.provider}
            schema={team_integration_schemas[integration.provider]}
            integration={integration}
            mutate={mutate}
          />
        ))}
        {not_connected_integrations.map(([key, entry]) => (
          <IntegrationCard key={key} provider={key as TeamIntegrationProvider} schema={entry} />
        ))}
        <Link m={{ t: 2 }} onClick={showChat} icon="arrow_forward">
          Request new integration
        </Link>
      </Div>
    </>
  );
};

export default TeamIntegrations;
