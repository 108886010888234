import { SourceTypes, SourceTypeSchema } from '../../../../typings/Integration.interface';

type IntegrationType = 'HMAC' | 'BASIC_AUTH' | 'API_KEY' | 'WEBHOOK';
export const integration_schemas: { [key in IntegrationType]: SourceTypeSchema | undefined } = {
  HMAC: {
    label: 'HMAC',
    category: 'http',
    features: {
      VERIFICATION: {
        required: true,
        fields: [
          {
            label: 'Algorithm',
            name: 'algorithm',
            type: 'select',
            features: ['VERIFICATION'],
            options: [
              {
                value: 'sha1',
                label: 'SHA-1',
              },
              {
                value: 'sha256',
                label: 'SHA-256',
              },
              {
                value: 'sha512',
                label: 'SHA-512',
              },
              {
                value: 'md5',
                label: 'MD5',
              },
            ],
          },
          {
            label: 'Encoding',
            name: 'encoding',
            type: 'select',
            features: ['VERIFICATION'],
            options: [
              {
                value: 'base64',
                label: 'base64',
              },
              {
                value: 'base64url',
                label: 'base64url',
              },
              {
                value: 'hex',
                label: 'hex',
              },
            ],
          },
          {
            label: 'Signature Header Key',
            name: 'header_key',
            type: 'text',
            placeholder: 'X-Some-Hmac-Signature',
            features: ['VERIFICATION'],
          },
          {
            label: 'Webhook Signing Secret',
            name: 'webhook_secret_key',
            type: 'password',
            placeholder: 'Secret key...',
            features: ['VERIFICATION'],
          },
        ],
      },
    },
  },
  BASIC_AUTH: {
    label: 'Basic Auth',
    category: 'http',
    features: {
      VERIFICATION: {
        required: true,
        fields: [
          {
            label: 'Username',
            name: 'username',
            type: 'text',
            required: false,
            features: ['VERIFICATION'],
          },
          {
            label: 'Password',
            name: 'password',
            type: 'password',
            required: false,
            features: ['VERIFICATION'],
          },
        ],
      },
    },
  },
  API_KEY: {
    label: 'API Key',
    category: 'http',
    features: {
      VERIFICATION: {
        required: true,
        fields: [
          {
            label: 'API Key Header',
            name: 'header_key',
            type: 'text',
            placeholder: 'X-Some-Api-Key',
            features: ['VERIFICATION'],
          },
          {
            label: 'API Key',
            name: 'api_key',
            type: 'password',
            placeholder: 'Secret key...',
            features: ['VERIFICATION'],
          },
        ],
      },
    },
  },
  WEBHOOK: undefined,
};

export const mergeIntegrationSchemas = (schemas: SourceTypes | undefined) => {
  if (!schemas) return schemas;

  return Object.entries({ ...schemas, ...integration_schemas }).reduce((acc, [key, schema]) => {
    if (!schema) return acc;

    acc[key] = schema;
    return acc;
  }, {} as SourceTypes);
};
