import { useField } from 'formik';
import { useContext, useMemo, useState } from 'react';
import useSWR from 'swr';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { Div } from '../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import resource_details_form_props from '../Forms/resource_details';
import source_configuration_form_props from '../Forms/prev_source_configuration';
import new_source_configuration_form_props from '../Forms/source_configuration';
import RadioCardsInput from '../../../../common/Form/Fields/RadioCardsInput';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { DashboardContext } from '../../DashboardContext';
import { mergeIntegrationSchemas } from '../../../../../configs/integration-schemas';
import { IconName } from '../../../../common/base/Icon';

const SourceSection = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { source_types: _source_types } = useContext(DashboardContext);
  const [{ value: use }] = useField('source_use');
  const [search_term, setSearchTerm] = useState<string>('');
  const use_source_types = useFeatureFlag('types');

  const source_types = useMemo(
    () =>
      use_source_types || !_source_types ? _source_types : mergeIntegrationSchemas(_source_types),
    [use_source_types, _source_types],
  );

  const filters: Record<string, any> = {
    limit: 100,
    name: search_term ? { contains: search_term } : undefined,
  };
  const { data: sources, isValidating } = useSWR(APIMethodKeys.sources.list(filters), () =>
    HookdeckAPI.sources.list(filters),
  );

  return (
    <>
      <RadioCardsInput
        m={{ b: 6 }}
        name={'source_use'}
        options={[
          {
            value: 'new',
            label: 'Create new source',
            description: 'Configure a new source in Hookdeck.',
          },
          {
            value: 'select',
            label: 'Use existing source',
            description: 'Re-use a source you’ve already created.',
            disabled: !sources?.models.length,
          },
        ]}
      />
      {use === 'select' && (
        <SelectInput
          name="source_id"
          label="Source"
          required
          search
          placeholder="Select..."
          search_placeholder="Find a source..."
          separator={false}
          borderless
          sublte_error
          options={
            sources?.models.map((source) => ({
              value: source.id,
              label: source.name,
              icon: (source_types?.[source.type || 'WEBHOOK']?.icon || 'source') as IconName,
            })) || []
          }
          width={100}
          block
          search_term={search_term}
          setSearchTerm={setSearchTerm}
          search_is_loading={isValidating}
        />
      )}
      {use === 'new' &&
        (use_source_types ? (
          <Div m={{ t: 6 }}>
            <new_source_configuration_form_props.Fields prefix="source" />
          </Div>
        ) : (
          <>
            <resource_details_form_props.Fields
              prefix="source"
              placeholder="shopify-prod"
              name_prefix="Source"
              name_required
            />
            <Div m={{ t: 6 }}>
              <source_configuration_form_props.Fields prefix="source" />
            </Div>
          </>
        ))}
    </>
  );
};

export default SourceSection;
