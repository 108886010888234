import { Formik } from 'formik';
import { useContext } from 'react';

import { StyledCard } from '../../../../common/base/Card';
import Text from '../../../../common/base/Text';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import Modal from '../../../../common/Modal';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { DashboardContext } from '../../DashboardContext';
import { Team } from '../../../../../../../../typings/Team.interface';

const NewTeamModal: React.FC<{
  organization_id: string;
  onClose: () => void;
  onSuccess: (team: Team) => void;
}> = ({ organization_id, onClose, onSuccess }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const { organizations } = useContext(DashboardContext);
  const organization_role = organizations?.find((o) => o.id === organization_id)?.role;

  const is_admin = organization_role === 'admin' || organization_role === 'owner';

  const onSubmit = (v) => {
    HookdeckAPI.teams
      .create({
        name: v.name,
        private: is_admin ? v.private : true,
        organization_id: organization_id,
      })
      .then((team) => {
        addToast('success', `Project ${v.name} created`);
        onSuccess(team);
      })
      .catch(() => {
        addToast('error', `Failed to create the project, please contact us.`);
      });
  };

  return (
    <Formik
      initialValues={{
        name: '',
        private: is_admin ? false : true,
      }}
      validate={(v) => {
        if (!v.name || v.name.length === 0) {
          return { name: 'Required' };
        }
        if (v.name && v.name.length > 40) {
          return { name: 'Name is longer then 40 characters' };
        }
      }}
      onSubmit={onSubmit}>
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Modal
            portal
            title="Create new project"
            cancel_label="Cancel"
            submit_label="Create Project"
            onSubmit={props.handleSubmit}
            submit_disabled={props.isSubmitting || !props.dirty || !props.isValid}
            submit_icon={props.isSubmitting ? 'loading' : 'add_circle'}
            onCancel={onClose}
            onClose={onClose}>
            <Text as="p" m={{ b: 4 }}>
              Projects house connections, routing logic, and event history.
            </Text>
            <TextInput name="name" label="Project name" required />
            <StyledCard p={4} flex={{ justify: 'space-between' }}>
              <Div m={{ r: 4 }}>
                <Text subtitle>Private project</Text>
                <Text muted>Can only be accessed by Organization Members you specify.</Text>
              </Div>
              <SwitchInput
                name="private"
                disabled={organization_role !== 'admin' && organization_role !== 'owner'}
              />
            </StyledCard>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

export default NewTeamModal;
