import { useField } from 'formik';
import { useContext } from 'react';

import { Grid, GridUnit } from '@hookdeck/theme';

import {
  Destination,
  DestinationAuthMethod,
  DestinationHTTPMethod,
  DestinationRateLimitPeriod,
} from '../../../../../../../../typings/Destination.interface';
import destination_auth_method_schemas from '../../../../../configs/destination-auth-method-schemas';
import LINKS from '../../../../../configs/links';
import { fieldName, isValidUrl } from '../../../../../utils';
import { ClickableArea, SelectButton } from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon, { IconName } from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import Dropdown from '../../../../common/Dropdown';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { DashboardContext } from '../../DashboardContext';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import TextSwitch from '../../../../common/base/TextSwitch';

type ErrorOfFormValue<T> = {
  [P in keyof T]?: T[P] extends object ? ErrorOfFormValue<T[P]> : string;
};

export const destination_form_types = {
  http: {
    label: 'HTTP',
    icon: 'http' as const,
    description: 'Deliver events to a public HTTP endpoint. e.g. an API.',
  },
  cli: {
    label: 'CLI',
    icon: 'terminal' as const,
    description: 'Receive events on your localhost.',
  },
  mock: {
    label: 'Mock API',
    icon: 'api' as const,
    description: 'Ingest events to inspect event headers and payloads.',
  },
} as const;

export interface PrevDestinationPartialConfigurationFormValues {
  type: keyof typeof destination_form_types;
  url: string | null;
  cli_path: string | null;
  mock_url: string;
  enable_rate_limit: boolean;
  rate_limit: string | null;
  rate_limit_period: DestinationRateLimitPeriod;
}

export interface DestinationConfigurationFormValues
  extends PrevDestinationPartialConfigurationFormValues {
  show_advanced: boolean;
  path_forwarding_disabled: boolean;
  auth_method: DestinationAuthMethod;
  http_method: DestinationHTTPMethod | '';
}

export const destination_partial_form_props = {
  postprocessValues: (values: PrevDestinationPartialConfigurationFormValues) => {
    return {
      url: values.type === 'mock' ? LINKS.mock_api : values.type === 'http' ? values.url : null,
      cli_path: values.type === 'cli' ? values.cli_path : null,
      rate_limit:
        values.type === 'cli'
          ? null
          : values.enable_rate_limit && values.rate_limit
            ? parseInt(values.rate_limit)
            : null,
      rate_limit_period: values.enable_rate_limit ? values.rate_limit_period : null,
    };
  },
  getInitialValues: (destination?: Destination): PrevDestinationPartialConfigurationFormValues => {
    const type = destination?.url ? (destination.url === LINKS.mock_api ? 'mock' : 'http') : 'cli';
    return {
      type,
      mock_url: LINKS.mock_api,
      url: destination?.url || '',
      cli_path: destination?.cli_path || '',
      enable_rate_limit: destination?.rate_limit !== null,
      rate_limit: destination?.rate_limit ? String(destination.rate_limit) : '',
      rate_limit_period: destination?.rate_limit_period || 'second',
    };
  },
  validate: async (values: PrevDestinationPartialConfigurationFormValues) => {
    const errors: ErrorOfFormValue<PrevDestinationPartialConfigurationFormValues> = {};
    if (values.type === 'http') {
      if (!values.url || values.url.length === 0) {
        errors.url = 'Required';
      }
      if (values.url) {
        if (!isValidUrl(values.url)) {
          errors.url = 'Must be a valid HTTP url';
        }
      }
    } else if (values.type === 'cli') {
      if (!values.cli_path || values.cli_path.length === 0) {
        errors.cli_path = 'Required';
      }

      if (
        values.cli_path &&
        !/^\/[0-9a-zA-Z\/\^\?\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\.\%\+\~_]*$/.test(values.cli_path)
      ) {
        errors.cli_path = 'Must be a valid pathname and start with "/"';
      }
    }

    if (values.enable_rate_limit && Number(values.rate_limit) <= 0) {
      errors.rate_limit = 'Must be greater than 0';
    }

    return errors;
  },
};

const destination_configuration_form_props = {
  postprocessValues: (values: DestinationConfigurationFormValues) => {
    const auth_method_configs = {};
    destination_auth_method_schemas[values.auth_method.type].fields.forEach((field) => {
      auth_method_configs[field.name] = values.auth_method.config[field.name];
    });

    return {
      ...destination_partial_form_props.postprocessValues(values),
      path_forwarding_disabled: values.path_forwarding_disabled,
      auth_method: {
        type: values.auth_method.type,
        config: auth_method_configs,
      },
      http_method: values.type === 'cli' ? null : values.http_method || null,
    };
  },
  getInitialValues: (destination?: Destination): DestinationConfigurationFormValues =>
    destination
      ? {
          ...destination_partial_form_props.getInitialValues(destination),
          show_advanced:
            destination.path_forwarding_disabled === true ||
            destination.auth_method.type !== 'HOOKDECK_SIGNATURE',
          path_forwarding_disabled: destination.path_forwarding_disabled,
          auth_method: destination.auth_method,
          http_method: destination.http_method || '',
        }
      : {
          show_advanced: false,
          enable_rate_limit: false,
          type: 'http',
          url: '',
          mock_url: LINKS.mock_api,
          cli_path: '',
          rate_limit: '',
          rate_limit_period: 'second',
          path_forwarding_disabled: false,
          auth_method: {
            type: 'HOOKDECK_SIGNATURE',
            config: {},
          },
          http_method: '',
        },
  validate: async (values: DestinationConfigurationFormValues) => {
    const errors: ErrorOfFormValue<DestinationConfigurationFormValues> = {
      auth_method: { config: {} },
      ...(await destination_partial_form_props.validate(values)),
    };

    destination_auth_method_schemas[values.auth_method.type].fields
      .filter((field) => field.required)
      .forEach((field) => {
        if (
          (!values.auth_method.config[field.name] ||
            values.auth_method.config[field.name].length === 0) &&
          errors.auth_method
        ) {
          errors.auth_method[field.name] = 'Required';
        }
      });

    return errors;
  },
  Fields: ({
    prefix,
    show_all,
    advanced_only,
    base_only,
    destination_type_as_radio = false,
  }: {
    prefix: string;
    show_all?: boolean;
    advanced_only?: boolean;
    base_only?: boolean;
    destination_type_as_radio?: boolean;
  }) => {
    const { team, organization } = useContext(DashboardContext);
    const [{ value: show_advanced }, , { setValue: setShowAdvanced }] = useField<boolean>(
      fieldName('show_advanced', prefix),
    );
    const [{ value: type }, , { setValue: setType }] = useField<
      keyof typeof destination_form_types
    >(fieldName('type', prefix));
    const [{ value: url }] = useField<string>(fieldName('url', prefix));
    const [{ value: cli_path }] = useField<string>(fieldName('cli_path', prefix));
    const [{ value: enabled_rate_limit }] = useField<boolean>(
      fieldName('enable_rate_limit', prefix),
    );
    const [{ value: rate_limit }] = useField<number>(fieldName('rate_limit', prefix));
    const [{ value: rate_limit_period }] = useField<DestinationRateLimitPeriod>(
      fieldName('rate_limit_period', prefix),
    );

    let show_rate_limit_warning = false;
    let rate_limit_per_second = rate_limit;
    if (rate_limit_period === 'minute') {
      rate_limit_per_second = rate_limit / 60;
    } else if (rate_limit_period === 'hour') {
      rate_limit_per_second = rate_limit / 3600;
    }
    if (rate_limit_per_second > team!.max_events_per_second) {
      show_rate_limit_warning = true;
    }

    const [{ value: auth_method }] = useField<DestinationAuthMethod>(
      fieldName('auth_method', prefix),
    );
    const [{ value: path_forwarding_disabled }, , { setValue: setPathForwardingDisabled }] =
      useField<boolean>(fieldName('path_forwarding_disabled', prefix));

    const show_search_tooltip =
      (type === 'cli' && cli_path?.includes('?')) || (type === 'http' && url?.includes('?'));

    const advanced_content = (
      <>
        <Div p={{ y: 2 }}>
          <Div flex={{ justify: 'space-between', align: 'center' }} p={{ x: show_all ? 0 : 4 }}>
            <Text size="s" subtitle flex={{ align: 'center' }}>
              Path Forwarding
              <Tooltip
                tooltip="Configure if the request path should be carried over and appended to your destination path."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.destinations_advanced_edit,
                }}>
                <Icon muted right pointer icon="info" />
              </Tooltip>
            </Text>
            <TextSwitch
              options={[
                { label: 'Enabled', key: false },
                { label: 'Disabled', key: true },
              ]}
              onSelect={(key: boolean) => setPathForwardingDisabled(key)}
              active={!!path_forwarding_disabled}
            />
          </Div>
        </Div>
        {['mock', 'http'].includes(type) && (
          <Div p={{ x: show_all ? 0 : 4, y: 2 }}>
            <Div flex={{ justify: 'space-between', align: 'center' }}>
              <Text size="s" subtitle flex={{ align: 'center' }}>
                Custom HTTP Method
                <Tooltip
                  tooltip="Force the requests to your destination to use a specific HTTP method. By default the request will be made with the same method as the original request."
                  cta={{
                    label: 'Learn more',
                    to: LINKS.product_docs.destinations_advanced_edit,
                  }}>
                  <Icon muted right pointer icon="info" />
                </Tooltip>
              </Text>
              <Div w={50} style={{ boxSizing: 'border-box' }}>
                <SelectInput
                  block
                  m={0}
                  p={{ l: 2 }}
                  name={fieldName('http_method', prefix)}
                  options={[
                    { value: '', label: 'Select one...' },
                    { value: 'GET', label: 'GET' },
                    { value: 'POST', label: 'POST' },
                    { value: 'PUT', label: 'PUT' },
                    { value: 'PATCH', label: 'PATCH' },
                    { value: 'DELETE', label: 'DELETE' },
                  ]}
                />
              </Div>
            </Div>
          </Div>
        )}
        <Div p={{ x: show_all ? 0 : 4, y: 2 }}>
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <Text size="s" subtitle flex={{ align: 'center' }} m={{ b: 0 }}>
              Authentication
              <Tooltip
                tooltip="Choose a authentication strategy to use when sending the HTTP request to your destination."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.destinations_auth_strategy,
                }}>
                <Icon muted right small pointer icon="info" />
              </Tooltip>
            </Text>
            <Div p={{ l: 2 }} w={50} style={{ boxSizing: 'border-box' }}>
              <SelectInput
                m={0}
                block
                required
                name={fieldName('auth_method.type', prefix)}
                options={Object.entries(destination_auth_method_schemas)
                  .filter(([key]) => {
                    if (
                      !organization?.feature_flags?.oauth2_authorization_code &&
                      key === 'OAUTH2_AUTHORIZATION_CODE'
                    ) {
                      return false;
                    }
                    return true;
                  })
                  .map(([key, config]) => ({
                    value: key,
                    label: config.label,
                  }))}
              />
            </Div>
          </Div>
          {auth_method && destination_auth_method_schemas[auth_method.type].fields.length > 0 && (
            <Div flex={{ wrap: true }}>
              {destination_auth_method_schemas[auth_method.type].fields.map((field, i) =>
                field.type === 'select' ? (
                  <GridUnit
                    size={
                      destination_auth_method_schemas[auth_method.type].fields.length === 1
                        ? 1
                        : 1 / 2
                    }
                    key={field.name}>
                    <SelectInput
                      m={
                        destination_auth_method_schemas[auth_method.type].fields.length === 1
                          ? { t: 3 }
                          : { r: i % 2 === 0 ? 2.5 : 0, l: i % 2 !== 0 ? 2.5 : 0, t: 3 }
                      }
                      block
                      label={field.label}
                      name={fieldName(`auth_method.config.${field.name}`, prefix)}
                      options={field.options ?? []}
                      required={!field.required ? false : true}
                    />
                  </GridUnit>
                ) : (
                  <GridUnit
                    size={
                      destination_auth_method_schemas[auth_method.type].fields.length === 1
                        ? 1
                        : 1 / 2
                    }
                    key={field.name}>
                    <TextInput
                      m={
                        destination_auth_method_schemas[auth_method.type].fields.length === 1
                          ? { t: 3 }
                          : { r: i % 2 === 0 ? 2.5 : 0, l: i % 2 !== 0 ? 2.5 : 0, t: 3 }
                      }
                      label={field.label}
                      type={field.type}
                      placeholder={field.placeholder}
                      default_value={''}
                      name={fieldName(`auth_method.config.${field.name}`, prefix)}
                      required={!field.required ? false : true}
                    />
                  </GridUnit>
                ),
              )}
            </Div>
          )}
        </Div>
      </>
    );

    if (advanced_only) {
      return advanced_content;
    }

    return (
      <>
        <Div>
          <Div flex={{ gap: 4 }}>
            {!destination_type_as_radio && (
              <Div w={50}>
                <Text subtitle size="s" m={{ b: 1 }}>
                  Destination Type
                  <Text danger as="span">
                    *
                  </Text>
                </Text>
                <Dropdown
                  parent_width={{ min: 536, max: 536 }}
                  renderToggle={(open, toggle) => (
                    <SelectButton
                      onClick={() => toggle(!open)}
                      block
                      icon={destination_form_types[type].icon as IconName}>
                      {destination_form_types[type].label}
                    </SelectButton>
                  )}
                  block>
                  {(toggle) => (
                    <>
                      <StyledCardSection muted p={{ x: 3, y: 1 }}>
                        <Text size="xs" subtitle>
                          Destination Types
                        </Text>
                      </StyledCardSection>
                      {Object.entries(destination_form_types).map(
                        ([key, { icon, label, description }], i) => (
                          <StyledCardSection key={key}>
                            <ClickableArea.Permission
                              p={{ y: 2, x: 3 }}
                              onClick={() => {
                                toggle(false);
                                setType(key as keyof typeof destination_form_types);
                              }}
                              block>
                              <Div flex={{ align: 'center' }}>
                                <Icon left icon={icon} />
                                <Text subtitle>{label}</Text>
                              </Div>
                              <Text muted>{description}</Text>
                            </ClickableArea.Permission>
                          </StyledCardSection>
                        ),
                      )}
                    </>
                  )}
                </Dropdown>
              </Div>
            )}
            <Div w={destination_type_as_radio ? 100 : 50}>
              {type === 'http' && (
                <TextInput
                  name={fieldName('url', prefix)}
                  label="Endpoint URL"
                  placeholder={`https://example.com`}
                  required
                  monospace
                  m={0}
                  w={100}
                />
              )}
              {type === 'mock' && (
                <TextInput
                  disabled
                  label="Mock URL"
                  monospace
                  name={fieldName('mock_url', prefix)}
                  m={0}
                />
              )}
              {type === 'cli' && (
                <TextInput
                  label="CLI Path"
                  name={fieldName('cli_path', prefix)}
                  placeholder="/webhooks"
                  m={0}
                  required
                  monospace
                />
              )}
            </Div>
          </Div>
          {type === 'http' &&
            (url?.includes('ngrok.io') || url?.includes('ngrok-free.app')) &&
            !show_search_tooltip && (
              <Text size="s" muted as="p" m={{ t: 2, b: 0 }}>
                Using Ngrok?{' '}
                <Link small primary href={LINKS.product_docs.cli} target="_blank" rel="noreferrer">
                  Try our CLI{' '}
                </Link>
                with free permanent URLs
              </Text>
            )}
          {show_search_tooltip && (
            <Text flex muted as="p" m={{ t: 2, b: 0 }}>
              <Icon icon="info" left={2} p={{ t: 0.5 }} small />
              <span>By setting query params, event query params will be ignored.</span>
            </Text>
          )}

          {['mock', 'http'].includes(type) && (
            <>
              <Div flex={{ justify: 'space-between', align: 'center' }} m={{ t: 6 }}>
                <Text subtitle size="s" flex={{ align: 'center' }}>
                  Max delivery rate
                  <Tooltip
                    tooltip="Enable events delivery rate to control the maximum throughput of events delivered to your destination."
                    cta={{
                      label: 'Learn more',
                      to: LINKS.product_docs.destinations_max_delivery_rate,
                    }}>
                    <Icon muted right pointer icon="info" />
                  </Tooltip>
                </Text>
                <Div flex={{ align: 'center' }}>
                  <SwitchInput name={fieldName('enable_rate_limit', prefix)} />
                </Div>
              </Div>
              {enabled_rate_limit && (
                <Div m={{ t: 2 }}>
                  <Grid>
                    <GridUnit size={1 / 2}>
                      <TextInput
                        m={{ r: 2, b: 0 }}
                        name={fieldName('rate_limit', prefix)}
                        default_value={
                          team?.max_events_per_second ? team?.max_events_per_second.toString() : '5'
                        }
                        type="number"
                        min={1}
                        required
                      />
                    </GridUnit>
                    <GridUnit size={1 / 2}>
                      <SelectInput
                        m={{ l: 2, b: 0 }}
                        block
                        name={fieldName('rate_limit_period', prefix)}
                        options={[
                          {
                            value: 'second',
                            label: 'per second',
                          },
                          {
                            value: 'minute',
                            label: 'per minute',
                          },
                          {
                            value: 'hour',
                            label: 'per hour',
                          },
                          {
                            value: 'concurrent',
                            label: 'concurrent',
                          },
                        ]}
                        required
                      />
                    </GridUnit>
                  </Grid>
                </Div>
              )}
              {show_rate_limit_warning && (
                <Div flex m={{ t: 3 }}>
                  <Icon muted icon="info" m={{ t: 0.5 }} left />
                  <Text muted size="s">
                    This value is higher than your project throughput of{' '}
                    {team?.max_events_per_second} events per second.{' '}
                    <Link icon="arrow_forward" to="/settings/project/quotas?view=throughput">
                      Increase it
                    </Link>
                  </Text>
                </Div>
              )}
            </>
          )}
        </Div>
        {!base_only && (
          <>
            <StyledCard m={{ t: 6 }} overflow_hidden raised>
              {!show_all && (
                <StyledCardSection muted={show_advanced}>
                  <ClickableArea
                    p={{ y: 2, x: 3 }}
                    flex={{ justify: 'space-between', align: 'center' }}
                    onClick={() => setShowAdvanced(!show_advanced)}>
                    <Text size="s" subtitle muted={!show_advanced}>
                      Advanced Configuration
                    </Text>
                    <Icon muted icon={show_advanced ? 'expand_less' : 'expand_more'} />
                  </ClickableArea>
                </StyledCardSection>
              )}
              {(show_all || show_advanced) && (
                <StyledCardSection p={{ y: 2 }}>{advanced_content}</StyledCardSection>
              )}
            </StyledCard>
          </>
        )}
      </>
    );
  },
};

export default destination_configuration_form_props;
