import { Form, Formik } from 'formik';
import { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import styled, { useTheme } from 'styled-components';

import { Promotion, Referrer, TrackingType } from '../../../../typings/Tracking.interface';
import Badge from '../../../common/base/Badge';
import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Text from '../../../common/base/Text';
import SelectRowsInput from '../../../common/Form/Fields/SelectRowsInput';
import TextAreaInput from '../../../common/Form/Fields/TextAreaInput';
import TextInput from '../../../common/Form/Fields/TextInput';
import { Div } from '../../../common/helpers/StyledUtils';
import { useToasts } from '../../../common/Toast';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { UserContext } from '../../../contexts/UserContext';
import useLocalStorage from '../../../hooks/useLocalStorage';
import FullscreenLayout from '../../../layouts/FullscreenLayout';
import { DashboardContext } from '../DashboardContext';

const StepBage = styled(Badge)`
  font-size: 10px;
  min-width: 16px;
  min-height: 16px;
  line-height: 16px;
  font-weight: 600;
  font-variant: unset;
`;

const USE_CASE_OPTIONS = [
  {
    label: 'Receive webhooks from sources like Shopify, Stripe, etc.',
    value: 'receive_webhooks',
  },
  {
    label: 'Send webhooks to URLs registered by developers',
    value: 'send_webhooks',
  },
  {
    label: 'Connect third party services like Slack, Hubspot, etc.',
    value: 'connect_services',
  },
  {
    label: 'Receive events from your users, SDKs, IoT devices, etc.',
    value: 'async_api',
  },
  {
    label: 'Test and debug webhooks on your localhost',
    value: 'test',
  },
];

const use_case_options = [...USE_CASE_OPTIONS].sort(() => Math.random() - 0.5);

const CreateOrganization: React.FC = () => {
  const { HookdeckAPI, setAPITeamId } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const { organization, mutateOrganization, mutateTeam } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const theme = useTheme();
  const submitRef = useRef(false);
  const history = useHistory();

  const [promotion] = useLocalStorage<Promotion | null>(TrackingType.promotion, null);
  const [referrer] = useLocalStorage<Referrer | null>(TrackingType.referrer, null);

  useEffect(() => {
    if (referrer || promotion) {
      const trackingData = {
        promotion: promotion?.slug,
        referrer: referrer?.slug,
      };
      HookdeckAPI.track.event('Signup tracked', trackingData).catch(() => null);
    }
    // Only execute on the client
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateOrcreateOrganization = (name: string) => {
    const onComplete = () => {
      HookdeckAPI.track.event('Completed Onboarding');
      return history.push('/create-first-connection');
    };
    if (organization) {
      return HookdeckAPI.organizations.update({ name }).then(() => {
        return onComplete();
      });
    }
    return HookdeckAPI.organizations.create({ name }).then(({ default_team, organization }) => {
      mutateOrganization(organization);
      mutateTeam(default_team);
      setAPITeamId(default_team.id);
      return onComplete();
    });
  };

  return (
    <FullscreenLayout center>
      <Div style={{ zIndex: 1, position: 'relative' }} w={{ px: 624 }}>
        <img
          src={`/images/cube-${theme.mode}.svg`}
          style={{
            zIndex: -1,
            left: '-100px',
            top: '-100px',
            transform: 'scale(0.5)',
            position: 'absolute',
          }}
        />
        <img
          src={`/images/cube-${theme.mode}.svg`}
          style={{
            zIndex: -1,
            right: '-200px',
            top: '100px',
            transform: 'scale(0.8) rotate(30deg)',
            position: 'absolute',
          }}
        />
        <img
          src={`/images/cube-${theme.mode}.svg`}
          style={{
            zIndex: -1,
            left: '-140px',
            bottom: '-100px',
            position: 'absolute',
            transform: 'scale(0.6) rotate(-25deg)',
          }}
        />
        <Formik
          initialValues={{
            name: organization?.name || '',
            discovery_method: '',
            use_case: '',
            use_case_other: '',
          }}
          validateOnMount
          validate={(v) => (!v.name || v.name.length === 0 ? { name: 'Required ' } : {})}
          onSubmit={async (values) => {
            if (submitRef.current === true) {
              return;
            }
            submitRef.current = true;

            const { name, discovery_method, use_case, use_case_other } = values;
            if (user) {
              const use_case_position = use_case_options.findIndex(
                ({ value }) => value === use_case,
              );
              HookdeckAPI.track
                .event('Submitted Onboarding', {
                  discovery_method,
                  use_case,
                  use_case_other,
                  use_case_position,
                  $set_once: { discovery_method, use_case, use_case_position, use_case_other },
                })
                .catch(() => null);
            }
            try {
              await updateOrcreateOrganization(name);
              submitRef.current = false;
            } catch {
              submitRef.current = false;
              addToast('error', 'Failed to create your organization, try again!');
            }
          }}>
          {(props) => (
            <Form>
              <Text heading size="2xl" m={{ b: 1 }}>
                Welcome to Hookdeck
              </Text>
              <Text muted size="l" m={{ b: 6 }}>
                Receive, process, and deliver messages across your event-driven applications.
              </Text>
              <StyledCard dashed muted m={{ b: 6 }}>
                <StyledCardSection p={{ x: 4, y: 4 }}>
                  <Text heading size="m" m={{ b: 2 }}>
                    How did you hear about Hookdeck?
                  </Text>
                  <TextInput
                    m={{ b: 0 }}
                    min_w={{ px: 360 }}
                    name="discovery_method"
                    placeholder={`Google search for "Event Gateway", X (Twitter), LinkedIn, YouTube, etc...`}
                  />
                </StyledCardSection>
              </StyledCard>
              <StyledCard m={{ b: 6 }}>
                <StyledCardSection p={4}>
                  <Text heading m={{ b: 0 }} flex={{ align: 'center', gap: 2 }}>
                    <StepBage muted>1</StepBage> How would you like to get started with Hookdeck?
                  </Text>
                  <Text muted m={{ b: 2 }}>
                    We'll tailor your onboarding experience and share relevant resources.
                  </Text>
                  <SelectRowsInput
                    placeholder="Select a use case..."
                    name="use_case"
                    options={[
                      ...use_case_options,
                      // { label: "I don't have a use case in mind yet", value: 'no_use_case' },
                      { label: "Other / I'm not sure", value: 'other' },
                    ]}
                  />
                  {props.values.use_case === 'other' && (
                    <TextAreaInput
                      name="use_case_other"
                      m={{ t: 2 }}
                      placeholder="Please tell us a bit more about your target use case..."
                      maxlength={250}
                    />
                  )}
                </StyledCardSection>
              </StyledCard>
              <StyledCard m={{ b: 6 }}>
                <StyledCardSection p={4}>
                  <Text heading m={{ b: 0 }} flex={{ align: 'center', gap: 2 }}>
                    <StepBage muted>2</StepBage> Create an organization
                  </Text>
                  <Text muted>
                    Organizations house projects and any team members you work with.
                  </Text>
                  <TextInput name="name" m={{ t: 2 }} required placeholder="Organization name..." />
                </StyledCardSection>
              </StyledCard>
              <Button
                block
                disabled={props.isSubmitting || !props.isValid}
                primary
                submit
                icon={props.isSubmitting ? 'loading' : 'success'}>
                {!props.isSubmitting && <>{organization ? 'Update' : 'Create'} Organization</>}
              </Button>
            </Form>
          )}
        </Formik>
      </Div>
    </FullscreenLayout>
  );
};

export default CreateOrganization;
