import { useField, useFormikContext } from 'formik';

import { Source } from '../../../../../../../../typings/Source.interface';
import { fieldName } from '../../../../../utils';
import { ClickableArea } from '../../../../common/base/Button';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import resource_details_form_props from './resource_details';
import { Div } from '../../../../common/helpers/StyledUtils';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import { SelectSourceType } from '../../GuidedConnection/Steps/SelectSourceType';
import styled from 'styled-components';
import Alert from '../../../../common/base/Alert';
import { useContext, useEffect, useState } from 'react';
import {
  IntegrationConfig,
  isSourceTypeFeatureUnmanaged,
  SourceAuthProvider,
  SourceType,
  SourceTypes,
} from '../../../../../../../../typings/Integration.interface';
import { DashboardContext } from '../../DashboardContext';
import field_formats from '../../../../../utils/field-formatters';
import { CustomResponseValue, Feature, useFeatures } from './form_features';

type ErrorOfFormValue<T> = {
  [P in keyof T]?: T[P] extends object ? ErrorOfFormValue<T[P]> : string;
};

type AllowedHTTPMethodConfig = {
  GET: boolean;
  POST: boolean;
  PUT: boolean;
  PATCH: boolean;
  DELETE: boolean;
};
export interface SourceConfigurationFormValues {
  show_advanced: boolean;
  enable_verification: boolean;
  enable_custom_response: boolean;
  type?: SourceType;
  config?: IntegrationConfig & {
    allowed_http_methods?: AllowedHTTPMethodConfig;
    custom_response?: CustomResponseValue;
    type?: SourceAuthProvider;
    auth?: IntegrationConfig;
  };
  verification?:
    | {
        type: SourceType;
        configs: { [key: string]: string };
      }
    | {};
}

const default_allowed_http_methods = {
  GET: false,
  POST: true,
  PUT: true,
  PATCH: true,
  DELETE: true,
};

const StyledHR = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.outline.neutral};
  border: none;
`;

function parseAllowedHTTPMethods(allowed_http_methods?: AllowedHTTPMethodConfig) {
  return Object.entries(allowed_http_methods || {})
    .filter(([, value]) => !!value)
    .map(([key]) => key);
}

function postprocessConfig(values: SourceConfigurationFormValues, source_types: SourceTypes) {
  const source_type = values.type ? source_types?.[values.type] : undefined;
  if (!source_type) {
    return {};
  }

  const verification = source_type?.features?.VERIFICATION;
  const verification_fields =
    (verification &&
      isSourceTypeFeatureUnmanaged(verification) &&
      verification?.auth &&
      !!values.verification &&
      'type' in values.verification &&
      verification.auth?.[values.verification.type]?.fields) ||
    (verification && 'fields' in verification && verification.fields) ||
    [];

  const type_config = Object.entries({ ...values, ...values.config }).reduce(
    (acc, [key, value]) => {
      verification_fields?.forEach((field) => {
        if (field.name === key && field.features?.includes('VERIFICATION')) {
          acc[key] = value;
        }
      });

      return acc;
    },
    {},
  );

  const config = {
    ...values.verification,
    ...(values.config || {}),
    ...type_config,
    allowed_http_methods: parseAllowedHTTPMethods(values.config?.allowed_http_methods),
    custom_response: values.enable_custom_response ? values.config?.custom_response : undefined,
    type: values.type !== values.config?.type ? values.config?.type : undefined,
  };

  return config;
}

const new_source_configuration_form_props = {
  postprocessValues: (values: SourceConfigurationFormValues, source_types: SourceTypes) => {
    if (values.enable_verification && 'type' in values && values.config === undefined) {
      values.config = {};
    }
    return {
      type: 'type' in values && values.type ? values.type : null,
      config:
        values.enable_verification && 'type' in values
          ? postprocessConfig(values, source_types)
          : 'type' in values && values.type === 'WEBHOOK'
            ? {
                allowed_http_methods: parseAllowedHTTPMethods(values.config?.allowed_http_methods),
                custom_response: values.enable_custom_response
                  ? values.config?.custom_response
                  : undefined,
              }
            : null,
    };
  },
  getInitialValues: (source?: Source): SourceConfigurationFormValues =>
    source
      ? {
          show_advanced: true,
          type: source.type,
          config: {
            ...source.config,
            custom_response: source.custom_response || {
              content_type: 'json',
              body: '',
            },
            allowed_http_methods: {
              GET: source.allowed_http_methods?.includes('GET') ?? false,
              POST: source.allowed_http_methods?.includes('POST') ?? false,
              PUT: source.allowed_http_methods?.includes('PUT') ?? false,
              PATCH: source.allowed_http_methods?.includes('PATCH') ?? false,
              DELETE: source.allowed_http_methods?.includes('DELETE') ?? false,
            },
            type: source?.verification?.type || source.type,
            auth:
              source.config && 'auth' in source.config
                ? source.config?.auth
                : source.verification?.configs,
          },
          enable_custom_response: !!source.custom_response,
          enable_verification: !!source.verification && !!Object.keys(source.verification).length,
          verification: source.verification
            ? {
                type: source.verification.type.toUpperCase(),
                configs: source.verification.configs,
              }
            : {},
        }
      : {
          show_advanced: false,
          enable_verification: false,
          config: {
            custom_response: {
              content_type: 'json',
              body: '',
            },
            allowed_http_methods: default_allowed_http_methods,
          },
          enable_custom_response: false,
        },
  validate: async (values: SourceConfigurationFormValues, source_types: SourceTypes) => {
    const errors: ErrorOfFormValue<SourceConfigurationFormValues> = {
      config: {} as any,
    };
    if (values.enable_verification) {
      if ('type' in values) {
        if (!values.type) {
          errors.type = 'Required';
        } else if ('config' in values) {
          const source_type_fields = [
            ...(source_types[values.type.toUpperCase()]?.VERIFICATION?.fields || []),
            ...(source_types[values.type.toUpperCase()]?.VERIFICATION?.fields || []),
          ];
          source_type_fields
            .filter((field) => field.features?.includes('VERIFICATION'))
            .forEach((field) => {
              if (field.required && !values.config?.[field.name]) {
                (errors.config as any)[field.name] = 'Required';
              }
            });
        }
      }
    }
    if (values.enable_custom_response) {
      if (values.config?.custom_response?.content_type === 'json') {
        try {
          JSON.parse(values.config?.custom_response?.body);
        } catch (e) {
          errors['config.custom_response'] = {
            body: 'Invalid JSON',
          };
        }
      }
    }
    return cleanseFormErrorObject(errors);
  },
  Fields: ({
    prefix,
    show_all,
    disable_name,
  }: {
    prefix: string;
    show_all?: boolean;
    disable_name?: boolean;
  }) => {
    const formikContext = useFormikContext();
    const { source_types } = useContext(DashboardContext);
    const [using_default_name, setUsingDefaultName] = useState(false);
    const [{ value: name }, , { setValue: setName }] = useField<string>(fieldName('name', prefix));
    const [{ value: show_advanced }, , { setValue: setShowAdvanced }] = useField<boolean>(
      fieldName('show_advanced', prefix),
    );
    const [{ value: type }] = useField<SourceType>(fieldName('type', prefix));
    const [{ value: enable_verification }, , { setValue: setEnableVerification }] =
      useField<boolean>(fieldName('enable_verification', prefix));
    const [, , { setValue: setVerificationType }] = useField<SourceType>(
      fieldName('config.type', prefix),
    );

    const selected_type = source_types?.[type];
    const configuration_alert = selected_type?.configuration_alert;
    const { required_features, advanced_features } = useFeatures(selected_type?.features || {});

    useEffect(() => {
      if (selected_type?.category === 'platform') {
        setName(field_formats.slugify(selected_type?.label?.toLocaleLowerCase() || ''));
        setUsingDefaultName(true);
      } else if (using_default_name) {
        setName('');
        setUsingDefaultName(false);
      }
      const verification = selected_type?.features.VERIFICATION;
      if (verification && isSourceTypeFeatureUnmanaged(verification) && verification.required) {
        setEnableVerification(true);
      }
    }, [selected_type, setEnableVerification]);

    useEffect(() => {
      if (!enable_verification) {
        formikContext.setFieldValue('verification', null);
        formikContext.setFieldValue('config.auth', null);
      }
    }, [enable_verification, setVerificationType, type]);

    return (
      <>
        <SelectSourceType prefix={prefix} />
        {(show_all || !!type) && (
          <>
            {configuration_alert && (
              <Alert inline info m={{ y: 4 }}>
                {configuration_alert}
              </Alert>
            )}
            {disable_name !== true && (
              <Div m={{ y: 4 }}>
                <resource_details_form_props.Fields
                  prefix={prefix}
                  key={selected_type?.label}
                  placeholder="shopify-prod"
                  name_prefix="Source"
                  help="Used as a unique identifier of your source in Hookdeck."
                  name_required
                  default_value={
                    selected_type?.category === 'platform'
                      ? selected_type?.label?.toLocaleLowerCase()
                      : undefined
                  }
                />
              </Div>
            )}
            <Div m={{ y: 4 }}>
              {required_features.map((feature) => (
                <Feature feature={feature} prefix={prefix} key={feature.type} />
              ))}
            </Div>
            {advanced_features.length > 0 ? (
              <Div m={{ y: 4, t: 8 }}>
                <Div flex={{ justify: 'flex-start', align: 'center', gap: 1 }}>
                  <ClickableArea
                    p={{ y: 0, x: 1 }}
                    m={{ l: -1 }}
                    rounded
                    style={{ width: 'auto' }}
                    flex={{ justify: 'flex-start', align: 'center' }}
                    onClick={() => setShowAdvanced(!show_advanced)}>
                    <Text size="s" subtitle muted={!show_advanced}>
                      Advanced Configuration
                    </Text>
                    <Icon muted icon={show_advanced ? 'expand_less' : 'expand_more'} />
                  </ClickableArea>
                  {show_advanced && <StyledHR />}
                </Div>
                {show_advanced &&
                  advanced_features.map((feature) => (
                    <Feature feature={feature} prefix={prefix} key={feature.type} />
                  ))}
              </Div>
            ) : null}
          </>
        )}
      </>
    );
  },
};

export default new_source_configuration_form_props;
