import { useField } from 'formik';
import Text from '../../base/Text';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import Button, { ClickableArea } from '../../base/Button';
import Dropdown from '../../Dropdown';
import { StyledCardSection } from '../../base/Card';
import Search from '../../Search';
import { Div } from '../../helpers/StyledUtils';
import useSWR from 'swr';
import Loading from '../../base/Loading';
import Icon, { IconName } from '../../base/Icon';
import Badge from '../../base/Badge';
import Tabs from '../../base/Tabs';
import { StyledSelect } from './SelectInput';

export type Item = {
  id: string;
  label: string;
  description?: string;
  icon: IconName;
  category?: string;
};

interface Props {
  name: string;
  search_placeholder: string;
  categories?: { label: string; key: string }[];
  getItemsKey: (search_term: string, category?: string) => string;
  getItems: (search_term: string, category?: string) => Promise<Item[]>;
  getItemsFooter?: (search_term: string, category?: string) => ReactNode;
  show_category_badge?: boolean;
  minimal?: boolean;
  outline?: boolean;
  onSelect?: (value: string) => void;
}

const ItemListPickerInput: React.FC<Props> = ({
  name,
  categories,
  getItems,
  getItemsKey,
  getItemsFooter,
  search_placeholder,
  show_category_badge = true,
  minimal = false,
  outline = false,
  onSelect,
}) => {
  const [{ value }, , { setValue }] = useField(name);
  const [fetch_items, setFetchItems] = useState<boolean>(false);
  const [search_term, setSearchTerm] = useState<string>('');
  const [category, setCategory] = useState<string>('all');

  const { data } = useSWR<Item[]>(
    (fetch_items || value) && `${getItemsKey(search_term, category)}::list-picker-${name}`,
    () => getItems(search_term, category),
  );

  const items_footer = useMemo(
    () => (getItemsFooter ? getItemsFooter(search_term, category) : null),
    [search_term, category, getItemsFooter],
  );

  const current_item = useMemo(
    () => value && data?.find((item) => item.id === value),
    [value, data],
  );
  const has_categories = !!categories && categories.length > 0;

  return (
    <>
      <Dropdown
        placement="bottom-start"
        parent_width
        onToggle={() => !fetch_items && setFetchItems(true)}
        renderToggle={(opened, toggle) => (
          <StyledSelect
            error={false}
            has_value={!!current_item}
            has_value_with_icon={!!current_item?.icon}
            borderless>
            {!!current_item && current_item?.icon && (
              <Div
                h={100}
                flex={{ align: 'center' }}
                style={{ position: 'absolute', cursor: 'pointer' }}>
                <Icon
                  icon={current_item.icon as IconName}
                  right={3}
                  height={{ px: 16 }}
                  onMouseDown={() => {
                    toggle(true);
                  }}
                />
              </Div>
            )}
            <Button
              block
              minimal
              outline={outline}
              label={current_item?.label || 'Select...'}
              muted={!current_item}
              onClick={() => toggle(true)}>
              <Text w={100} align="left">
                {current_item?.label || 'Select...'}
              </Text>
            </Button>
            <Icon
              icon="expand_all"
              muted={!current_item}
              right={-7}
              padding={{ t: 2 }}
              style={{ position: 'absolute', cursor: 'pointer' }}
            />
          </StyledSelect>
        )}>
        {(toggle) => (
          <>
            <StyledCardSection p={{ x: 3, t: 3, b: has_categories ? 0 : 3 }}>
              <Search
                onChange={setSearchTerm}
                value={search_term}
                placeholder={search_placeholder}
                focus
              />
              {has_categories && (
                <Div p={{ t: 2 }}>
                  <Tabs
                    compact
                    border={false}
                    active_tab={category || 'all'}
                    onTabSelected={(key) => setCategory(key)}
                    tabs={[{ key: 'all', label: 'All' }, ...categories]}
                  />
                </Div>
              )}
            </StyledCardSection>
            <StyledCardSection max_h={{ px: 240 }} scroll p={{ y: 2 }}>
              {!data && (
                <Div p={{ x: 3, y: 2 }} flex={{ align: 'center', justify: 'center' }}>
                  <Loading />
                </Div>
              )}
              {data && data.length === 0 && (
                <Text p={{ x: 3, y: 2 }} muted>
                  No results found
                </Text>
              )}
              {data &&
                data.map((item, i) => {
                  return (
                    <Fragment key={item.id}>
                      <ClickableArea
                        p={{ x: 3, y: 2.5 }}
                        flex={{ justify: 'space-between', align: 'center' }}
                        onClick={() => {
                          setValue(item.id);
                          toggle(false);
                          setSearchTerm('');
                          onSelect?.(item.id);
                        }}>
                        <Div flex={{ align: 'center', gap: 2 }}>
                          <Icon icon={item.icon} muted />
                          {item.label}
                          {item.description && (
                            <Text size="s" muted>
                              ({item.description})
                            </Text>
                          )}
                          {item.id === value && <Badge muted>Selected</Badge>}
                        </Div>
                        {show_category_badge && item.category && (
                          <Badge muted>{item.category}</Badge>
                        )}
                      </ClickableArea>
                    </Fragment>
                  );
                })}
            </StyledCardSection>
            {items_footer && <StyledCardSection>{items_footer}</StyledCardSection>}
          </>
        )}
      </Dropdown>
    </>
  );
};

export default ItemListPickerInput;
