import { useField } from 'formik';
import styled from 'styled-components';

import {
  Destination,
  DestinationAuthMethod,
  DestinationAuthMethodConfig,
  DestinationAuthMethodType,
  DestinationHTTPMethod,
  DestinationRateLimitPeriod,
} from '../../../../../../../../typings/Destination.interface';
import { fieldName } from '../../../../../utils';
import { ClickableArea } from '../../../../common/base/Button';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import resource_details_form_props from './resource_details';
import { SelectDestinationType } from '../../GuidedConnection/Steps/SelectDestinationType';
import LINKS from '../../../../../configs/links';
import Alert from '../../../../common/base/Alert';
import {
  DestinationType,
  isDestinationTypeFeatureUnmanaged,
} from '../../../../../../../../typings/Integration.interface';
import { useContext, useEffect } from 'react';
import { DashboardContext } from '../../DashboardContext';
import { Feature, useFeatures } from './form_features';

type ErrorOfFormValue<T> = {
  [P in keyof T]?: T[P] extends object ? ErrorOfFormValue<T[P]> : string;
};

export interface DestinationPartialConfigurationFormValues {
  type?: DestinationType;
  enable_rate_limit?: boolean;
  enable_verification?: boolean;
  show_advanced?: boolean;
  auth_method?: DestinationAuthMethod;
  config?: DestinationAuthMethodConfig & {
    type: DestinationAuthMethodType;
    rate_limit?: string;
    rate_limit_period?: DestinationRateLimitPeriod;
    http_method?: DestinationHTTPMethod | '';
    path_forwarding_disabled: boolean;
    url?: string | null;
    auth?: DestinationAuthMethodConfig;
  };
}

export interface DestinationConfigurationFormValues {
  show_advanced: boolean;
  type?: DestinationType;
  auth_method?: DestinationAuthMethod;
  enable_verification: boolean;
  enable_rate_limit: boolean;
  config?: DestinationAuthMethodConfig & {
    type: DestinationAuthMethodType;
    rate_limit?: string;
    rate_limit_period?: DestinationRateLimitPeriod;
    http_method?: DestinationHTTPMethod | '';
    path_forwarding_disabled: boolean;
    url?: string | null;
    auth?: DestinationAuthMethodConfig;
  };
}

const StyledHR = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.outline.neutral};
  border: none;
`;

const new_destination_configuration_form_props = {
  postprocessValues: (values: DestinationConfigurationFormValues) => {
    const auth_type =
      (values.config && 'type' in values.config ? values.config.type : values.auth_method?.type) ||
      values.config?.type ||
      undefined;
    return {
      type: values.type,
      config: {
        ...(values.config || {}),
        http_method: values.type === 'CLI' ? null : values.config?.http_method || null,
        url:
          values.type === 'MOCK_API'
            ? LINKS.mock_api
            : values.type === 'HTTP'
              ? values.config?.url
              : null,
        rate_limit: values.enable_rate_limit ? Number(values.config?.rate_limit) : null,
        rate_limit_period: values.enable_rate_limit ? values.config?.rate_limit_period : null,
        path_forwarding_disabled: values.config?.path_forwarding_disabled,
        type: auth_type === values.type ? undefined : auth_type,
      },
      auth_method:
        values.auth_method && values.auth_method?.type !== values.type
          ? {
              type: values.auth_method?.type,
              config: values?.auth_method?.config,
            }
          : undefined,
    };
  },
  getInitialValues: (destination?: Destination): DestinationPartialConfigurationFormValues => {
    const auth_type =
      (destination?.auth_method?.type as DestinationAuthMethodType | undefined) ||
      ('HOOKDECK_SIGNATURE' as DestinationAuthMethodType);
    return {
      type: destination?.type as DestinationType | undefined,
      enable_rate_limit: false,
      enable_verification: !!auth_type && auth_type !== 'HOOKDECK_SIGNATURE',
      show_advanced: !!destination,
      auth_method: destination?.auth_method,
      config: {
        http_method: destination?.http_method || 'POST',
        url: destination?.url || '',
        rate_limit: destination?.rate_limit ? String(destination.rate_limit) : '',
        rate_limit_period: destination?.rate_limit_period || 'second',
        path_forwarding_disabled: destination?.path_forwarding_disabled ?? false,
        ...destination?.auth_method?.config,
        type: auth_type,
        auth: destination?.auth_method?.config,
      } as DestinationConfigurationFormValues['config'],
    };
  },
  validate: async (values: DestinationPartialConfigurationFormValues) => {
    const errors: ErrorOfFormValue<DestinationPartialConfigurationFormValues> = {};

    if (values.enable_rate_limit && Number(values.config?.rate_limit) <= 0) {
      errors['config.rate_limit'] = 'Must be greater than 0';
    }

    return errors;
  },
  Fields: ({ prefix, disable_name }: { prefix: string; disable_name?: boolean }) => {
    const { destination_types } = useContext(DashboardContext);
    const [{ value: show_advanced }, , { setValue: setShowAdvanced }] = useField<boolean>(
      fieldName('show_advanced', prefix),
    );
    const [{ value: type }] = useField<DestinationType>(fieldName('type', prefix));
    const [, , { setValue: setEnableVerification }] = useField<boolean>(
      fieldName('enable_verification', prefix),
    );

    const selected_type = destination_types?.[type];
    const configuration_alert = selected_type?.configuration_alert;
    const { required_features, advanced_features } = useFeatures(
      selected_type?.features || {},
      'auth_method',
    );

    useEffect(() => {
      const verification_feature = selected_type?.features?.VERIFICATION;
      if (
        isDestinationTypeFeatureUnmanaged(verification_feature) &&
        verification_feature?.required
      ) {
        setEnableVerification(true);
      }
    }, [selected_type, setEnableVerification]);

    return (
      <>
        <SelectDestinationType key={type} prefix={prefix} />
        {selected_type && (
          <>
            {configuration_alert && (
              <Alert inline info m={{ y: 4 }}>
                {configuration_alert}
              </Alert>
            )}
            {disable_name !== true && (
              <Div m={{ y: 4 }}>
                <resource_details_form_props.Fields
                  prefix={prefix}
                  placeholder={selected_type.label.toLowerCase()}
                  name_prefix="Destination"
                  help="Used as a unique identifier of your destination in Hookdeck."
                  name_required
                />
              </Div>
            )}
            <Div m={{ y: 4 }}>
              {required_features.map((feature) => (
                <Feature feature={feature} prefix={prefix} key={feature.type} />
              ))}
            </Div>
            {advanced_features.length > 0 && (
              <Div m={{ y: 4, t: 8 }}>
                <Div flex={{ justify: 'flex-start', align: 'center', gap: 1 }}>
                  <ClickableArea
                    p={{ y: 0, x: 1 }}
                    m={{ l: -1 }}
                    rounded
                    style={{ width: 'auto' }}
                    flex={{ justify: 'flex-start', align: 'center' }}
                    onClick={() => setShowAdvanced(!show_advanced)}>
                    <Text size="s" subtitle muted={!show_advanced}>
                      Advanced Configuration
                    </Text>
                    <Icon muted icon={show_advanced ? 'expand_less' : 'expand_more'} />
                  </ClickableArea>
                  {show_advanced && <StyledHR />}
                </Div>
                {show_advanced &&
                  advanced_features.map((feature) => (
                    <Feature feature={feature} prefix={prefix} key={feature.type} />
                  ))}
              </Div>
            )}
          </>
        )}
      </>
    );
  },
};

export default new_destination_configuration_form_props;
