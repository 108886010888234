import { useContext } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { DashboardContext } from '../DashboardContext';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import Container from '../../../common/base/Container';
import Text from '../../../common/base/Text';
import Badge from '../../../common/base/Badge';
import { Div, StyledUtilsProps } from '../../../common/helpers/StyledUtils';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import { ClickableArea } from '../../../common/base/Button';
import Icon, { IconName } from '../../../common/base/Icon';
import Link from '../../../common/base/Link';
import LINKS from '../../../../configs/links';

const Grid = styled(Div)<{
  columns?: number;
  gapX?: string;
  gapY?: string;
  marginTop?: string;
}>(
  ({ columns, gapX, gapY, marginTop }) => css`
    display: grid;
    grid-template-columns: repeat(${columns || 2}, minmax(0, 1fr));
    gap: 32px;
    ${gapX && `column-gap: ${gapX};`}
    ${gapY && `row-gap: ${gapY};`}
    margin-top: ${marginTop || '24px'};

    @media (max-width: 800px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  `,
);

interface GetStartedCardProps {
  heading?: React.ReactNode;
  text?: React.ReactNode;
  icon: IconName;
  onClick?: () => void;
  to?: string;
  href?: string;
  completed?: boolean;
  variant?: 'step' | 'docs';
}

const StyledCardWrapper = styled(StyledCard)`
  overflow: hidden;
`;
const StyledIconWrapper = styled.div`
  display: none;

  ${StyledCardWrapper}:hover & {
    display: contents;
  }
  * {
    cursor: pointer;
  }
`;

const Dot = styled(Div)<{
  muted?: boolean;
  danger?: boolean;
  success?: boolean;
  primary?: boolean;
  warning?: boolean;
  dark?: boolean;

  px?: number;
}>(
  ({ muted, danger, primary, warning, dark, success, px, theme }) => css`
    width: ${px || 8}px;
    height: ${px || 8}px;
    border-radius: 50%;
    ${muted &&
    css`
      background-color: ${theme.colors.on.neutral.secondary_neutral};
    `};

    ${dark &&
    css`
      background-color: ${theme.colors.on.neutral.primary_neutral};
    `};

    ${danger &&
    css`
      background-color: ${theme.colors.on.neutral.danger};
    `};

    ${primary &&
    css`
      background-color: ${theme.colors.on.neutral.primary};
    `};

    ${warning &&
    css`
      background-color: ${theme.colors.on.neutral.warning};
    `};

    ${success &&
    css`
      background-color: ${theme.colors.on.neutral.success};
    `};
  `,
);
const GetStartedCard = ({
  variant = 'docs',
  heading = '',
  text = '',
  icon,
  onClick,
  to,
  href,
  completed = false,
}: GetStartedCardProps) => {
  const theme = useTheme();
  return (
    <StyledCardWrapper raised={1}>
      <StyledCardSection h={100}>
        <ClickableArea
          h={100}
          style={{ height: '100%' }}
          as={href && Link}
          highlight={completed}
          disabled={completed}
          to={to}
          href={href}
          target={href && '_blank'}
          onClick={onClick}
          no_underline
          p={4}>
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <Badge
              subtle={!completed}
              muted={completed}
              h={{ px: 32 }}
              w={{ px: 32 }}
              icon={icon}
            />
            {completed ? (
              <Div flex={{ align: 'center', gap: 1 }}>
                <Icon muted icon="check" />
                <Text muted subtitle>
                  Done
                </Text>
              </Div>
            ) : variant === 'docs' ? (
              <StyledIconWrapper>
                <Icon icon="link" muted large />
              </StyledIconWrapper>
            ) : (
              <Div flex={{ align: 'center', gap: 2 }}>
                <Dot primary />
                <Text muted subtitle>
                  To-do
                </Text>
              </Div>
            )}
          </Div>
          <Text heading muted={completed} text_wrap m={{ t: 8 }}>
            {heading}{' '}
            {variant === 'step' && !completed && (
              <StyledIconWrapper>
                <Icon icon="arrow_forward" />
              </StyledIconWrapper>
            )}
          </Text>
          <Text muted text_wrap>
            {text}
          </Text>
        </ClickableArea>
      </StyledCardSection>
    </StyledCardWrapper>
  );
};

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledImageWrapper = styled.div<StyledUtilsProps>(
  ({ theme }) => css`
    border-radius: ${theme.radius.normal};
    overflow: hidden;
    border: ${theme.border};
    box-shadow: ${theme.elevation[1]};
    padding: 0;
    margin-bottom: 24px;
    line-height: 0;
  `,
);

const UseCase = ({
  img,
  heading,
  text,
  href,
}: {
  img: string;
  heading: string;
  text: string;
  href: string;
}) => {
  return (
    <Div>
      <StyledImageWrapper>
        <StyledImg src={img} alt={heading} />
      </StyledImageWrapper>
      <Text size="l" heading>
        {heading}
      </Text>
      <Text m={{ b: 3, t: 2 }} muted>
        {text}
      </Text>
      <Link href={href} target="_blank">
        Quickstart <Icon style={{ textDecoration: 'none' }} icon="link" pointer />
      </Link>
    </Div>
  );
};

const GetStartedView: React.FC = () => {
  const { get_started_pending_steps, has_connection, has_team_members_or_invites } =
    useContext(DashboardContext);
  const theme = useTheme();

  return (
    <StyledViewWrapper>
      <StyledViewContent>
        <PageNav breadcrumb={[{ icon: 'grid_view', title: 'Get Started' }]}></PageNav>
        <Container xlarge h={100} p={{ x: 15, t: 10, b: 22 }}>
          <Div style={{ maxWidth: '1024px', margin: '0 auto' }}>
            <Div flex={{ align: 'center', gap: 4 }}>
              <Text as="h1" size="2xl" heading>
                Get Started
              </Text>
              {get_started_pending_steps > 0 && (
                <Badge max_h={10} subtle>
                  {get_started_pending_steps}
                </Badge>
              )}
            </Div>
            <Grid marginTop="16px">
              <GetStartedCard
                variant="step"
                heading="Create a connection"
                icon="connections"
                text="Create your first connection to begin routing events through Hookdeck."
                to="/create-first-connection"
                completed={has_connection}
              />
              <GetStartedCard
                variant="step"
                heading="Invite team members"
                icon="group"
                text="Invite team members to your organization for a free 14-day trial period."
                to="/settings/organization/members?tab=invited"
                completed={has_team_members_or_invites}
              />
            </Grid>
            <Div m={{ t: 18 }}>
              <Text size="xl" heading>
                Hookdeck use cases
              </Text>
            </Div>
            <Grid gapY="56px">
              <UseCase
                img={`/images/receive-events-${theme.mode}.svg`}
                heading="Receive events"
                text="Use Hookdeck as your inbound webhook infrastructure."
                href="https://hookdeck.com/docs/receive-webhooks"
              />
              <UseCase
                img={`/images/send-events-${theme.mode}.svg`}
                heading="Send events"
                text="Use Hookdeck as your outbound webhook infrastructure."
                href="https://hookdeck.com/docs/send-webhooks"
              />
              <UseCase
                img={`/images/third-party-${theme.mode}.svg`}
                heading="Third party event routing"
                text="Use Hookdeck to integrate third-party APIs and services."
                href="https://hookdeck.com/docs/third-party-routing"
              />
              <UseCase
                img={`/images/async-api-${theme.mode}.svg`}
                heading="Asynchronous API"
                text="Use Hookdeck as your asynchronous API infrastructure."
                href="https://hookdeck.com/docs/asynchronous-apis"
              />
            </Grid>
            <Div m={{ t: 18 }}>
              <Text size="xl" heading>
                Hookdeck basics
              </Text>
            </Div>
            <Grid columns={3}>
              <GetStartedCard
                heading="Connections"
                icon="connections"
                text="Connections route events from a request source to an event destination."
                href={LINKS.product_docs.connections}
              />
              <GetStartedCard
                heading="Sources"
                icon="source"
                text="A source represents a service that sends HTTP requests to Hoodkeck."
                href={LINKS.product_docs.sources}
              />
              <GetStartedCard
                heading="Destinations"
                icon="destinations"
                text="Destinations represent endpoints your events can be delivered to."
                href={LINKS.product_docs.destinations}
              />
              <GetStartedCard
                heading="Requests"
                icon="requests"
                text="Requests are HTTP requests that Hookdeck receives from a source."
                href={LINKS.product_docs.requests}
              />
              <GetStartedCard
                heading="Events & Attempts"
                icon="events"
                text="Events are outgoing requests from Hookdeck. Attempts are specific event delivery efforts."
                href={LINKS.product_docs.events}
              />
              <GetStartedCard
                heading="Issues & Notifications"
                icon="inbox"
                text="Track problems and communicate resolutions with your team."
                href={LINKS.product_docs.issues}
              />
            </Grid>
            <Div m={{ t: 18 }}>
              <Text size="xl" heading>
                Learn more
              </Text>
            </Div>
            <Grid columns={3}>
              <GetStartedCard
                heading="Transformations"
                icon="transformation"
                text="Transformations let you modify events prior to delivery."
                href={LINKS.product_docs.transformations}
              />
              <GetStartedCard
                heading="Filters"
                icon="filter"
                text="Conditionally allow and route events based on their contents."
                href={LINKS.product_docs.filters}
              />
              <GetStartedCard
                heading="Retries"
                icon="retry"
                text="Manually or automatically retry previous event deliveries."
                href={LINKS.product_docs.retries}
              />
            </Grid>
          </Div>
        </Container>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default GetStartedView;
