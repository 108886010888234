import { useContext } from 'react';
import { DashboardContext } from '../scenes/DashboardScene/DashboardContext';
import { FeatureFlags } from '../../../../../typings/Organization.interface';

export function useFeatureFlag<T extends keyof FeatureFlags>(flag: T) {
  const { organization } = useContext(DashboardContext);
  if (!organization) return false;

  return organization.feature_flags?.[flag] || false;
}
